import React, { useRef, useState, useEffect } from 'react'
import './style.scss'

const ProgressIndicator = ({ progress }) => {
  const radius = 80;
  const stroke = 3;
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;

  // Calculate the stroke dashoffset
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <div className="progress-indicator">
      <div className="progress-indicator-bg">
        <svg height={radius * 2} width={radius * 2}>
          <circle
            stroke="white"
            fill="transparent"
            strokeWidth={stroke}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          />
        </svg>
      </div>
      <div className="progress-indicator-progress">
        <svg height={radius * 2} width={radius * 2}>
          <circle
            stroke="white"
            fill="transparent"
            strokeWidth={stroke}
            strokeDasharray={circumference + ' ' + circumference}
            style={{ strokeDashoffset, transform: "rotate(-90deg)", transformOrigin: "center" }}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          />
        </svg>
      </div>
    </div>
  );
};

export default ProgressIndicator
