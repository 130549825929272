import React, { useRef, useState, useEffect } from 'react'
import './style.scss'

import { recommendedBooksLabel } from '../../config/copy'

const RecommendedBooks = ({ bookItems, selectBook }) => {
  return (
    <div className="recommended-books">
      <div className="recommended-books-container">
        <div className="recommended-books-container__title">{ recommendedBooksLabel }</div>
        <div className="recommended-books-container__items">
          {bookItems.map((bookItem, index) => (
            <div className="recommended-books-container__item" key={index}>
              <div className="recommended-books-container__item-image-container">
                <div className="recommended-books-container__item-image" style={{ backgroundImage: `url(${bookItem.imageUrl})` }} onClick={() => selectBook(bookItem)}></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RecommendedBooks
