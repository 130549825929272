/**
 *
 * @param src - string - image-url
 * @returns {Promise<Image>}
 */
export const preloadImage = src => new Promise(function(resolve, reject) {
  const img = new Image()
  img.onload = () =>resolve(img)
  img.onerror = reject
  img.src = src
})

/**
 *
 * @param srcArray - Array<String> - image-urls
 * @returns {Promise<Awaited Array<Image>}
 */
export const preloadImages = srcArray =>Promise.all(srcArray.map(preloadImage));


/* example:
const srcArray = [
  'https://homepage/images/cat.png',
  'https://homepage/images/airplane.png'
  ]

preloadImages(srcArray)
  .then(images => console.log('Preloaded all', images))
  .catch(err => console.error('Failed', err))

 */
