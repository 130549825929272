import React, { useRef } from 'react'
import './style.scss'

const ErrorMessage = ({ errorMessage, actionMessage, onActionClick }) => {

  return (
    <div className="error-message">
      {errorMessage}
      {actionMessage ? <a href="#" onClick={onActionClick}>{actionMessage}</a> : null}
    </div>
  )
}

export default ErrorMessage
