// src/config/config.js

import { serverUrl } from './api-config';

import { getParameterByName } from '../utils/helpers'

export const isMobile = /Mobile/i.test(navigator.userAgent);
export const isTablet = /Tablet|iPad/i.test(navigator.userAgent);

export const isProduction = process.env.NODE_ENV === 'production';
export const siteRoot = serverUrl; // address to access (considering sub folder locally and online)
// export const siteRoot = isProduction ? '' : serverUrl; // address to access from local development
export const apiEndpoint = siteRoot + '/api/api.php';

export const siteRootProduction = siteRoot.includes('-staging') ? siteRoot.replace('-staging', '') : siteRoot;

export const siteRootBase = 'valentines-2024';
export const siteShareRoot = siteRoot.includes('-staging') ? siteRoot : 'love.resn.co.nz';

export const resultsFolder = 'api/results';
export const resultsThumbsFolder = 'results-thumbs';

export const ttsAudioExtension = 'mp3';

export const ttsAudioDelay = 2.4; // 2.5; // 4.5;

export const ttsFeedbackDelay = getParameterByName('feedbackDelay') || 0.08;
export const ttsFeedbackAmount = getParameterByName('feedback') || 0.08;
export const ttsPitchShift = getParameterByName('pitch') || 4; // 3; // 4.9;
export const ttsPlaybackRate = getParameterByName('rate') || 0.75; // 4.9;

export const introDuration = 5;
export const introOutDuration = 0.75;
export const introStartedOutOffset = 0.2;
export const introOutStartOffset = -0.05;

export const numRecommendations = 3;

const contactEmailSubject = 'Sheer Cupidity - AI Valentine’s Card';
// const contactEmailBody = 'I saw your Resn AI Valentine’s site and I want to talk about our next AI project...';

export const resnSiteLink = 'https://resn.co.nz';
export const contactUrl = `mailto:newbusiness@resn.co.nz?subject=${encodeURIComponent(contactEmailSubject)}`; // &body=${contactEmailBody}`;

export const limitCount = 15;
export const lockTime = 3600000; // one hour in milliseconds
