import React, { useState, useEffect } from 'react'
import './style.scss'

import { generatingLoaderTitle, generatingIndicatorTexts } from '../../config/copy'

import { ReactComponent as Heart } from '../../assets/drop-heart.svg'

import ProgressIndicator from '../ProgressIndicator'
import LoadingTextCarousel from '../LoadingTextCarousel'

const GeneratingIndicator = () => {

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let start = null;
    const durationFirstPhase = 60 * 1000; // 60 seconds for 0 to 90%
    const durationSecondPhase = 60 * 1000; // 60 seconds for 90 to 95%
    const endProgressFirstPhase = 90;
    const endProgressSecondPhase = 97;

    const animate = (timestamp) => {
      if (!start) start = timestamp;
      const runtime = timestamp - start;
      let progress;

      if (runtime < durationFirstPhase) {
        progress = Math.min(endProgressFirstPhase * Math.pow(runtime / durationFirstPhase, 0.7), endProgressFirstPhase);
      } else {
        const runtimeSecondPhase = runtime - durationFirstPhase;
        if (runtimeSecondPhase < durationSecondPhase) {
          progress = endProgressFirstPhase + Math.min((endProgressSecondPhase - endProgressFirstPhase) * Math.pow(runtimeSecondPhase / durationSecondPhase, 0.55), endProgressSecondPhase - endProgressFirstPhase);
        } else {
          progress = endProgressSecondPhase;
        }
      }

      setProgress(progress);

      if (runtime < (durationFirstPhase + durationSecondPhase)) {
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);

    return () => {
      cancelAnimationFrame(animate);
    }

    // let currentItemIndex = Math.floor(Math.random() * generatingIndicatorTexts.length);
    // const carousel = document.getElementById('loading-indicator-container');

    // function updateCarousel() {
    //   carousel.innerHTML = `<div class="generating-indicator__text">${generatingIndicatorTexts[currentItemIndex]}</div>`;

    //   currentItemIndex++;
    //   if (currentItemIndex >= generatingIndicatorTexts.length) {
    //     currentItemIndex = 0;
    //   }
    // }

    // let carouselInterval = setInterval(updateCarousel, 5 * 1000);
    // updateCarousel();

    // return () => {
    //   cancelAnimationFrame(animate);
    //   clearInterval(carouselInterval);
    // }
  }, []);

  return (
    <div className="generating-indicator">
      <div className="generating-indicator__title">{generatingLoaderTitle}</div>
      <div className="generating-indicator__container">
        <div className="generating-indicator__progress"><ProgressIndicator progress={progress} /></div>
        <div className="generating-indicator__indicator-icon"><Heart></Heart></div>
      </div>
      <div className="generating-indicator__cycling-container">
        <LoadingTextCarousel textItems={generatingIndicatorTexts} />
      </div>
      {/* <div className="generating-indicator__cycling-container" id="loading-indicator-container">
        <div class="generating-indicator__text">${generatingIndicatorTexts[0]}</div>
      </div> */}
      {/* <div className="generating-indicator__indicator"></div> */}
    </div>
  )
}

export default GeneratingIndicator
