// src/config/copy.js

export const visitSiteLabel = `Visit`;
export const visitSiteButtonLabel = `resn.co.nz`;

export const contactUsLabel = `Want to discuss your next AI project?`;
export const contactUsButtonLabel = `Get in touch`;

export const disclaimerButtonLabel = `Disclaimer`;
export const cookieButtonLabel = "Cookies";




// export const shareCopyLinkLabel = `Copy Link`;
// export const shareTwitterLabel = `Twitter`;
// export const shareFacebookLabel = `Facebook`;
// export const shareDownloadLabel = `Download`;
// export const shareShareTrayLabel = `Share`;

// export const introText = `Grab the mistletoe and pour yourself a stiff glass of eggnog! This season Resn brings you a steamy holiday tale hot enough to roast your chestnuts.`

export const startButtonLabel = `Start`;
export const viewCardButtonLabel = `View Card`;

export const nextButtonLabel = `Next`;
export const enterButtonLabel = `Send to Cupid!`;

export const senderHeadline =`Love ain’t easy. But, Cupid is here to help.`
export const subjectALabel = `Who is your card from?`; // `Santa Claus`;
export const subjectADescLabel = `Tell us about yourself`;


export const receiverHeadline =`Who’s your lucky valentine?`
export const subjectBLabel = `Who is the card to?`; // `The ghost of Christmas past`;
export const subjectBDescLabel = `Tell us about your valentine`;

export const senderRandomiseLabel = `Don’t know what to write?`;
export const receiverRandomiseLabel = `Don’t know what to write?`;

export const randomizeSenderButtonLabel = `Randomise`;
export const randomizeReceiverButtonLabel = `Randomise`;


export const shareDisclaimer = 'Your images and data will not be shared';

export const senderNamePlaceholder = `Sender’s Name`;
export const senderDescPlaceholder = `I’m a blonde gal that loves cheese`;

export const receiverNamePlaceholder = `Your Valentine’s Name`;
export const receiverDescPlaceholder = `He’s got a amazing mane of red hair and loves crochet`;

export const recommendedBooksLabel = `Check out another card`;

export const generatingLoaderTitle = `Hang on a minute while Cupid readies his arrow`;

export const shareTitle = `Sheer Cupidity - by Resn`;

export const genericSharePrepopulatedCopy = `Check out the Valentine’s Card I made with Sheer Cupidity by Resn`;
export const twitterSharePrepopulatedCopy = `Check out the Valentine’s Card I made with Sheer Cupidity by @resn_has_no_i`;

export const genericShareOthersPrepopulatedCopy = `Check out this Valentine’s Card made with Sheer Cupidity by Resn`;
export const twitterShareOthersPrepopulatedCopy = `Check out this Valentine’s Card made with Sheer Cupidity by @resn_has_no_i`;

export const generatingIndicatorTexts = [
  `Choo-choo-choosing You`,
  `Riding the Love Train`,
  `Stringing Cupid's Bow`,
  `Kissing Under the Moonlight`,
  `Sampling Your Love Potion`,
  `Whispering Sweet Nothings`,
  `Turning Off the Lights`,
  `Closing the Curtains`,
  `Getting in the Mood`,
  `Lighting the Candles`,
  `Nibbling Your Love Muffin`,
  `Confessing My Desires`,
  `Entering the Tunnel of Love`,
  `Making Your Dreams Come True`,
  `Lasting All Night Long`,
];

export const generateAnotherLabel = `Create another`;
export const generateYourOwnLabel = `Create your own`;

export const tooManyTriesErrorMessage = `Uh oh. You've exhausted Cupid. Try back in an hour.`;

export const serverGeneralErrorTitle = `Uh oh!`;
export const serverTooBusyErrorTitle = `Too many love requests`;

export const serverErrorMessage = `Oops! Cupid broke his bowstring. Please try again.`;
export const serverTooBusyErrorMessage = `Cupid is so hot right now! Please try again later.`; // \n\nWhile you’re waiting, take a look at our website
export const serverRejectionErrorMessage = `Oops! Cupid broke his bowstring. Please try again.`;
export const serverResponseErrorMessage = `Uh oh! OpenAI is currently experiencing outages. Please try again soon.`;
export const contentPolicyErrorMessage = `Sorry, Cupid’s lawyer says no. Try something different.`;
export const serverForbiddenMessage = `Sorry, Cupid is on a break. Gods need holidays, too. Please try again later.`;
export const shareDataError = `Sorry, Cupid couldn’t find this link.`;

export const disclaimerTitle = `Disclaimer`;
export const disclaimerText = `The content provided in this experience is generated by artificial intelligence. Resn is not responsible for the quality or appropriateness of the AI-generated material. To make this experience work, we need to send the information you submit to OpenAI for processing and store it on our servers to let you share your results. We won’t share your Valentine’s card or any of your personal data.`


export const placeholderRomanceItems = [
// "Santa",
];

export const placeholderGeneralItems = [
"A rainbow chicken",
"An angel",
"A Snowman",
"Ebenezer Scrooge",
"Elvis Presley",
"The Queen",
"The The Ghost of Christmas Past",
"A polar bear",
"A penguin",
"Leonardo da Vinci",
"Cleopatra",
"A unicorn",
"Harry Potter",
"Sherlock Holmes",
"Albert Einstein",
"Julius Caesar",
"A dragon",
"Winston Churchill",
"Marilyn Monroe",
"A mermaid",
"Gandhi",
"Mother Teresa",
"A wizard",
"A samurai",
"Galileo Galilei",
"Napoleon Bonaparte",
"Joan of Arc",
"A talking tree",
"Frida Kahlo",
"A gnome",
"Thomas Edison",
"Alexander the Great",
"A fairy",
"Confucius",
"A robot",
"King Arthur",
"A yeti",
"Pablo Picasso",
"Socrates",
"William Shakespeare",
"Julius Caesar",
"Charles Darwin",
"Nikola Tesla",
"Abraham Lincoln",
"Ludwig van Beethoven",
"Vincent van Gogh",
"Sigmund Freud",
"George Washington",
"Michelangelo",
"Jane Austen",
"Florence Nightingale",
"Plato",
"Aristotle",
"Archimedes",
"Joan of Arc",
"Helen Keller",
"Harriet Tubman",
"Marco Polo",
"Louis Pasteur",
"Tutankhamun",
"Rembrandt",
"Edgar Allan Poe",
"Charles Dickens",
"Benjamin Franklin",
"Alexander Hamilton",
"Margaret Thatcher",
"Madonna",
"Beyoncé",
"Meryl Streep",
"Julia Roberts",
"Britney Spears",
"Rihanna",
"Celine Dion",
"Tina Turner",
"Jane Austen",
"A sphinx",
"Robin Hood",
"A leprechaun",
"Isaac Newton",
"A centaur",
"Beethoven",
"A griffin",
"A pirate",
"Casanova",
"A Viking",
"Mozart",
"A siren",
"A sphynx cat",
"A pharaoh",
"A ninja",
"A genie",
"A cyclops",
"A gargoyle",
"A mummy",
"A werewolf",
"A vampire",
"A troll",
"Dorothy from The Wizard of Oz",
"A mad scientist",
"A gladiator",
"A knight in shining armor",
"A Greek god",
"A Roman goddess",
"A sphinx",
"A Minotaur",
"A talking mirror",
"A magical unicorn",
"A fire-breathing dragon",
"A witch on a broomstick",
"A ghostly apparition",
"An enchanted prince",
"A cursed princess",
"A fairy godmother",
"A merman",
"A leviathan",
"An orc warrior",
"A dwarven blacksmith",
"An elven archer",
"A hobbit",
"A centaur archer",
"A griffin rider",
"A pixie",
"A chimera",
"A phoenix rising from ashes",
"A dryad guarding a tree",
"A harpy",
"Medusa",
"A kraken emerging from the sea",
"A satyr playing a flute",
"An alien visitor",
"A time traveler",
"A steampunk inventor",
"A cyberpunk hacker",
"A superhero in a cape",
"A villain with a diabolical plan",
"A giant robot",
"A mystical shaman",
"A space explorer",
"A samurai warrior",
"A ninja in the shadows",
"A Viking berserker",
"A Roman centurion",
"A Spartan warrior",
"A medieval bard",
"A Renaissance painter",
"An art deco architect",
"A jazz musician in the Roaring Twenties",
"A 1950s rock-and-roll star",
"A disco dancer from the 1970s",
"A punk rocker from the 1980s",
"A grunge musician from the 1990s",
"A Y2K tech wizard",
"A millennium futurist",
"A cow",
"An elephant",
"A Bengal tiger",
"A blue whale",
"A chimpanzee",
"A red panda",
"An emperor penguin",
"A giraffe",
"A great white shark",
"A grizzly bear",
"A kangaroo",
"A koala",
"A leopard",
"An orangutan",
"A polar bear",
"A rhinoceros",
"A snow leopard",
"A wolf",
"A zebra",
"A hippopotamus",
"A bald eagle",
"An octopus",
"A sloth",
"A flamingo",
"A cheetah",
"An anaconda",
"A platypus",
"A peacock",
"A meerkat",
"A sea turtle",
"A hummingbird",
"A falcon",
"A lynx",
"A porcupine",
"A tarantula",
"A jellyfish",
"A Komodo dragon",
"An armadillo",
"A manatee",
"A pangolin",
"An iguana",
"A mystical phoenix",
"A time-traveling explorer",
"An enchanted forest guardian",
"A mythical kraken",
"A galactic space captain",
"A stealthy ninja",
"A majestic sea serpent",
"An ancient sorcerer",
"A cybernetic super soldier",
"A legendary samurai warrior",
"An enigmatic time traveler",
"A fierce dragon slayer",
"A daring pirate captain",
"A brilliant steampunk inventor",
"An intrepid arctic explorer",
"A mythical unicorn rider",
"A masterful swordsmith",
"A dashing highwayman",
"An enchanted mermaid queen",
"A skilled gladiator",
"A renowned grand wizard",
"A noble elven archer",
"A mysterious fortune teller",
"A courageous lionhearted knight",
"A fiery volcano goddess",
"A skilled falconer",
"A charismatic jazz singer",
"An ancient Egyptian pharaoh",
"A Victorian-era detective",
"A 1960s hippie",
"A Greek philosopher",
"A jazz singer from the Harlem Renaissance",
"An Elizabethan playwright",
"A cyberpunk hacker",
"A samurai warrior",
"A 1920s flapper",
"A medieval alchemist",
"A baroque composer",
"An astronaut from the Apollo missions",
"A prohibition-era gangster",
"A 1980s video game designer",
"A Renaissance scientist",
"A silent film actor",
"A beatnik poet",
"A WWII fighter pilot",
"A Gothic novelist",
"A 1970s disco DJ",
"An Impressionist painter",
"A 1930s jazz trumpeter",
"A medieval jester",
"A Victorian inventor",
"A 2000s pop idol",
"A steampunk engineer",
"A 1940s big band leader",
"A Roman gladiator",
"An early 20th-century explorer",
"A Mayan astronomer",
"A Neolithic hunter",
"A 1950s beatnik",
"A medieval blacksmith",
"A 1980s arcade champion",
"A Regency era ballroom dancer",
"A Byzantine emperor",
"A 1990s dot-com entrepreneur",
"An ancient Greek athlete",
"A 1940s film noir detective",
"A Ming Dynasty scholar",
"A 1970s punk band member",
"A Renaissance herbalist",
"A 1920s aviator",
"A medieval knight",
"A 2000s social media influencer",
"A 1950s diner waitress",
"A 1960s folk singer",
"A Viking explorer",
"An Art Nouveau artist",
"A 1930s radio broadcaster",
"A medieval monk",
"A 1980s Wall Street banker",
"A 1990s grunge fan",
"An ancient Roman senator",
"A 1970s environmental activist",
"A 1950s Hollywood starlet",
"A 1960s astronaut",
"A Baroque violinist",
"A 1980s punk vocalist",
"An ancient Babylonian astronomer",
"A Renaissance cartographer",
"A 1920s silent movie star",
"A medieval monk illuminator",
"A Victorian botanist",
"A 1950s rockabilly musician",
"An 18th-century pirate",
"A 1990s rave DJ",
"An ancient Greek tragedian",
"A 1930s jazz pianist",
"A 2000s indie game developer",
"A Ming Dynasty potter",
"A 1970s funk singer",
"A medieval tapestry weaver",
"An Art Nouveau glassmaker",
"A 1980s breakdancer",
"A 1990s sitcom actor",
"An ancient Persian poet",
"A 1960s peace activist",
"A Regency era novelist",
"A 1950s beat poet",
"An Elizabethan actor",
"A 1940s swing dancer",
"A 2000s fashion designer",
"A Byzantine icon painter",
"A 1930s film director",
"A 1970s disco producer",
"A medieval stonemason",
"A 1980s graffiti artist",
"A Renaissance alchemist",
"A 1920s Broadway star",
"A 1960s mod",
"A Victorian engineer",
"A 1950s television presenter",
"An 18th-century landscape painter",
"A 1990s skateboarder",
"An ancient Roman architect",
"A 1930s Harlem Renaissance writer",
"A 2000s blogger",
"A medieval troubadour",
"A 1970s soul musician",
"An Art Deco furniture designer",
"A 1980s New Wave singer",
"A 1990s hip-hop artist",
"An ancient Chinese calligrapher",
"A 1960s surf rock guitarist",
"A Renaissance sculptor",
"A 1940s film star",
"A Victorian novelist",
"A 1950s jazz saxophonist",
"An 18th-century explorer",
"A 2000s reality TV star",
"A medieval armourer",
"A 1980s arcade game developer",
"A 1990s alternative rock guitarist",
"An ancient Egyptian scribe",
"A 1930s aviator",
"A 2000s YouTube influencer",
"A Gothic cathedral builder",
"A 1960s beat musician",
"A Renaissance painter",
"A 1920s blues singer",
"A Victorian poet",
"A 1950s sci-fi author",
"An 18th-century composer",
"A 1990s rave culture enthusiast",
"A medieval herbalist",
"A 1970s glam rock star",
"An Art Nouveau architect",
"A 1980s synth-pop musician",
"A 1990s grunge band drummer",
"An ancient Viking skald",
"A Renaissance inventor",
"A 1940s noir detective",
"A Victorian suffragette",
"A 1950s hot rod enthusiast",
"An 18th-century navigator",
"A 1990s Internet pioneer",
"A medieval castle builder",
"A 1970s prog rock keyboardist",
"An Art Deco graphic designer",
"A 1980s hip-hop MC",
"A 1990s tech entrepreneur",
"An ancient Greek potter",
"A 1960s folk protest singer",
"A Renaissance humanist",
"A 1920s Harlem jazz musician",
"A Victorian explorer",
"A 1950s atomic age designer",
"An 18th-century philosopher",
"A 2000s emo band member",
"A medieval glassblower",
"An Art Nouveau jeweler",
"A majestic lion",
"A playful dolphin",
"A mysterious owl",
"A swift cheetah",
"A graceful swan",
"A fierce tiger",
"A wise elephant",
"A cunning fox",
"A loyal dog",
"A curious cat",
"An elegant giraffe",
"A resilient tortoise",
"A colorful parrot",
"A noble horse",
"A spirited kangaroo",
"A serene koala",
"A powerful gorilla",
"A sleek panther",
"A diligent beaver",
"A patient penguin",
"A charming hummingbird",
"A robust bison",
"A vibrant peacock",
"A gentle deer",
"A bold eagle",
"A whimsical monkey",
"A dainty butterfly",
"A hardworking ant",
"A sturdy rhinoceros",
"A playful otter",
"A regal falcon",
"A mischievous raccoon",
"A stealthy leopard",
"A vibrant flamingo",
"A sociable meerkat",
"A formidable crocodile",
"A tranquil dove",
"A resourceful octopus",
"A radiant starfish",
"A vigilant hawk",
"A whimsical seahorse",
"A robust walrus",
"A harmonious dolphin",
"A steadfast camel",
"A perceptive chameleon",
"A buoyant jellyfish",
"A commanding orca",
"A nimble squirrel",
"A tranquil turtle",
"A radiant goldfish",
"A whimsical fairy",
"A fearless astronaut",
"A wise elder",
"An enigmatic spy",
"A charming bard",
"A rogue pirate",
"A noble knight",
"An ancient mummy",
"A cunning detective",
"A powerful sorcerer",
"A fierce gladiator",
"A mysterious fortune teller",
"A brave firefighter",
"An adventurous archaeologist",
"A genius scientist",
"A relentless bounty hunter",
"A charismatic leader",
"A savage barbarian",
"A skilled archer",
"A ruthless assassin",
"A serene monk",
"A daring stunt performer",
"A talented musician",
"A graceful dancer",
"A visionary artist",
"A skilled blacksmith",
"A stoic soldier",
"A cunning thief",
"A compassionate healer",
"A relentless journalist",
"A dashing pilot",
"A noble diplomat",
"A creative inventor",
"A daring explorer",
"A cunning lawyer",
"A dedicated teacher",
"A resourceful survivalist",
"A powerful wizard",
"A skilled chef",
"A fearless ranger",
"A charming con artist",
"A ruthless gangster",
"A legendary athlete",
"A gifted child",
"A hardened mercenary",
"A sophisticated spy",
"A mystical druid",
"A daring acrobat",
"A vigilant guard",
"A whimsical jester",
]

export const placeholderItems = [...placeholderRomanceItems, ...placeholderGeneralItems];
