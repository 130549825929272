import { Howler } from 'howler'
import Howl from './Howl.js'
import TTSSoundPlayer from './TTS/TTSSoundPlayer';

import {
  ttsFeedbackDelay,
  ttsFeedbackAmount,
  ttsPitchShift,
  ttsPlaybackRate
} from '../../config/config.js';

// ************* LOADING AUDIO LOGIC *************
import mainThemeMusic from '../../assets/audio/main_song.mp3'
import generatingMusic from  '../../assets/audio/wait_music_A.mp3'
import resultsMusic from '../../assets/audio/results_song.mp3'
import buttonPress1 from '../../assets/audio/button_press_1.mp3'
import buttonPress2 from '../../assets/audio/button_press_2.mp3'
import buttonPress3 from '../../assets/audio/button_press_3.mp3'
import itsTime from '../../assets/audio/its_time.mp3'

const AUDIO_URLS = {
  mainThemeMusic,
  generatingMusic,
  resultsMusic,
  buttonPress1,
  buttonPress2,
  buttonPress3,
  itsTime
}
const manifest = {
  id: 'audio',
  items: [
    {
      url: AUDIO_URLS.mainThemeMusic,
      options: {
        sprite: {
          loop: [0, 170000, true],
        },
      },
    },
    {
      url: AUDIO_URLS.generatingMusic,
      options: {
        sprite: {
          loop: [70, 26667, true],
        },
      },
    },
    {
      url: AUDIO_URLS.resultsMusic,
      options: {
        sprite: {
          loop: [51, 120000, true],
        },
      },
    },
    { url: AUDIO_URLS.buttonPress1 },
    { url: AUDIO_URLS.buttonPress2 },
    { url: AUDIO_URLS.buttonPress3 },
    { url: AUDIO_URLS.itsTime },
  ],
}

const loadedTracks = {}
let lastAttemptedTrackPlay = undefined

let ttsPlayer = null
let curTTSSoundTimeout = null


/**
 * Manages the audio and triggering of audio
 */
class _AudioService {
  sprite_ids = {} // .{sound_id}.{sprite_id} = howler playback id
  playingBackgroundLoopId = undefined

  userMuted= localStorage.getItem('userMuted') === 'true'
  tabVisible = document.visibilityState === 'visible'

  constructor() {
    this._updateMuteState()

    document.addEventListener('visibilitychange', () => {
      this.tabVisible = document.visibilityState === 'visible'
      this._updateMuteState()
    })

    const loadPromisesArray = manifest.items.map(item => {
      return new Promise(async resolve => {
        const obj = new Howl(
          item.url,
          item.extension,
          item.options?.concurrent,
          item.options?.sprite
        )
        obj.load().then(() => {
          resolve(obj)
        })
      })
    })
    Promise.allSettled(loadPromisesArray).then(results => {
      results.forEach((result, index) => {
        if (result.status === 'fulfilled') {
          // console.log('loaded', manifest.items[index].url, result.value)
          loadedTracks[manifest.items[index].url] = result.value

          if(lastAttemptedTrackPlay === manifest.items[index].url) {
            this.playBackgroundLoop(manifest.items[index].url)
            lastAttemptedTrackPlay = undefined
          }
        }
      })
    })
  }

  playBackgroundLoop = (id, fadeOutDur = 0, fadeInDur = 0, fadeToVol = 1) => {
    if(!loadedTracks[id]) {
      // console.error('No audio found loaded for', id)
      lastAttemptedTrackPlay = id
      return
    }

    const prevLoop = loadedTracks[this.playingBackgroundLoopId]
    if(prevLoop === loadedTracks[id]) {
      return
    }

    if(prevLoop) {
      prevLoop.fade(0, fadeOutDur)
    }

    loadedTracks[id].volume = 0

    this.playingBackgroundLoopId = id
    this.playSpriteIfNot(id, 'loop', fadeInDur, true, fadeToVol)
  }

  playSpriteIfNot = (sound_id, sprite, fade = 0, loop = false, fadeToVol = 1) => {
    const sound = loadedTracks[sound_id]
    if (!sound) {
      return
    }

    const spriteID = this.sprite_ids[sound_id]?.[sprite]
    const playing = spriteID !== undefined && sound.playing(spriteID)
    sound.fade(fadeToVol, fade) // fade even if playing
    if (!playing) {
      sound.loop = loop
      this.sprite_ids[sound_id] = this.sprite_ids[sound_id] || {}
      this.sprite_ids[sound_id][sprite] = sound.play(sprite)
    }
  }

  playItsTime = () => {
    if(loadedTracks[AUDIO_URLS.itsTime]) {
      loadedTracks[AUDIO_URLS.itsTime].play()
    }
  }

  playRandomButtonPress = () => {
    const randomNum = Math.ceil(Math.random() * 3)
    const soundUrl = AUDIO_URLS[`buttonPress${randomNum}`]

    if(loadedTracks[soundUrl]) {
      loadedTracks[soundUrl].play()
    }
  }

  initTTSAudio = () => {
    ttsPlayer = new TTSSoundPlayer(ttsFeedbackDelay, ttsFeedbackAmount, ttsPitchShift, ttsPlaybackRate)
    ttsPlayer.mute(this.isMuted)
  }

  loadTTSAudio = (soundUrl) => {
    if (!ttsPlayer) {
      this.initTTSAudio()
    }
    ttsPlayer.load(soundUrl)
  }

  playTTSAudio = (soundUrl, delay) => {
    if (ttsPlayer) {
      this.stopTTSAudio()
      if (ttsPlayer.curSoundUrl !== soundUrl) {
        ttsPlayer.load(soundUrl)
      }
      curTTSSoundTimeout = setTimeout(() => {
        ttsPlayer.play()
      }, delay * 1000)
    }
  }

  stopTTSAudio = () => {
    // stop timeout
    if (curTTSSoundTimeout != null) {
      clearTimeout(curTTSSoundTimeout)
      curTTSSoundTimeout = null
    }
    if (ttsPlayer) {
      ttsPlayer.stop()
    }
  }

  setUserMute = (mute) => {
    this.userMuted = mute
    localStorage.setItem('userMuted', mute)
    this._updateMuteState()
  }

  _updateMuteState = () => {
    this.isMuted = this.userMuted || !this.tabVisible
    Howler.mute(this.isMuted)
    if (ttsPlayer) {
      ttsPlayer.mute(this.isMuted)
    }
  }
}

const AudioService = new _AudioService()

export { AudioService, AUDIO_URLS }
