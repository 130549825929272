import React, { useState } from 'react';
import './style.scss'
import {AudioService} from "../../services/audio/AudioService";


const MuteButton = () => {
  const [isMuted, setIsMuted] = useState(AudioService.userMuted);

  const handleMuteClick = () => {
    setIsMuted((prevIsMuted) => {
      AudioService.setUserMute(!prevIsMuted)
      return !prevIsMuted
    });
  };

  return (
    <button className={`mute-button ${isMuted ? 'muted' : ''}`} onClick={ handleMuteClick }>
      <svg
        viewBox="0 0 19 17"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" y="7.1665" width="1.79167" height="8.95833"/>
        <rect x="11.25" y="3.5835" width="1.79167" height="12.5417"/>
        <rect x="16.625" y="10.75" width="1.79167" height="5.375"/>
        <rect x="5.875" width="1.79167" height="16.125"/>
      </svg>
    </button>
  );
};

export default MuteButton;
