// src/config/api-config.js


export const serverUrl = 'https://presents.resn.global/valentines-2024'; // temp server


// OR LocalServers
// export const serverUrl = 'http://valentines-2024.test';
// export const serverUrl = 'https://localhost/valentines-2024';
// export const serverUrl = 'http://localhost/valentines-2024';
