import React from 'react'
import './style.scss'

const Button = ({ buttonLabel, className, onClick }) => {

  return (
    <button className={`button ${className || ''}`} onClick={onClick}>{buttonLabel}</button>
  )
}

export default Button
