// src/config/testResponseData.js

import TestImage from '../assets/img/test-cover.jpg';

export const testResponse = {
  "id": "2100ce5f6610",
  "tagline": "My Meat loves your Sweet",
  "title": "Sizzling affection",
  "poem": "Oh Banana, my sweet, succulent muse,\nYour juicy embrace is what Meaty would choose.\nYou peel back the essence of true love's delight,\nWhile I, the Sausage, sizzle in the passionate night.\n\nNo tropical sun could rival your glow,\nNor any butcher match my Meaty tableau.\nUnited we are, a dish quite divine,\nIn a love salad, together we entwine.\"",
  "coverImageUrl": TestImage,
  "subjects": "Sausage >> Banana"
};