// src/helpers.js

export function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

export function wait(duration) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}

export function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function getPathValueFromUrl(pathIdentifier) {
  const pathValue = window.location.pathname.includes(pathIdentifier) ? window.location.pathname.split(pathIdentifier)[1] : null;
  if (window.location.pathname.includes('?')) {
    return pathValue.split('?')[0];
  }
  return pathValue;
}

export function updateUrlParameter(name, value) {
  const url = new URL(window.location.href);
  const searchParams = url.searchParams;
  searchParams.set(name, value);

  url.search = searchParams.toString();
  url.search = url.search.replace(/=&/g, '&'); // remove empty params
  if (url.search.slice(-1) === '=') {
    url.search = url.search.slice(0, -1); // remove trailing empty param
  }

  window.history.replaceState({}, '', url.toString());
}

export function isOdd(num) {
  return num % 2;
}

export function isEqual(obj1, obj2) {
  if (obj1 === obj2) {
    return true;
  }
  if (!obj1 || !obj2) {
    return false;
  }
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}
