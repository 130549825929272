import React, { useRef, useState, useEffect } from 'react'
import './style.scss'

const LoadingTextCarousel = ({ textItems, className, carouselDelayOffset }) => {

  // add ref for text carousel container
  const textCarouselContainerRef = useRef()

  useEffect(() => {
    let currentItemIndex = Math.floor(Math.random() * textItems.length);
    const carousel = textCarouselContainerRef.current;

    function updateCarousel() {
      carousel.innerHTML = `<div class="loading-text-carousel-container_item">${textItems[currentItemIndex]}</div>`;

      currentItemIndex++;
      if (currentItemIndex >= textItems.length) {
        currentItemIndex = 0;
      }
    }

    let carouselInterval = null;

    carouselDelayOffset = carouselDelayOffset || 0;
    
    // set timeout
    setTimeout(() => {
      carouselInterval = setInterval(updateCarousel, 5 * 1000);
      updateCarousel();
    }, carouselDelayOffset * 1000);

    return () => {
      clearInterval(carouselInterval);
    }
  }, []);

  return (
    <div className={`loading-text-carousel-container ${className || ''}`} id="loading-text-carousel-container" ref={textCarouselContainerRef}>
    </div>
  );
};

export default LoadingTextCarousel
