import React from 'react'
import ReactGA from "react-ga4";

import { ReactComponent as ResnDrop } from '../../assets/icons/resn-drop.svg'

import { resnSiteLink, contactUrl } from '../../config/config'

import { visitSiteLabel, visitSiteButtonLabel, contactUsLabel, contactUsButtonLabel } from '../../config/copy'

import './style.scss'
import MuteButton from "../MuteButton";
import {AudioService} from "../../services/audio/AudioService";


const HeaderEl = ({ screenState, introStartedOut, isErrorPageVisible }) => {

  const trackExternalClick = (actionLabel) => {
    AudioService.playRandomButtonPress()
    ReactGA.event({
      category: 'External Links',
      action: actionLabel,
      label: actionLabel,
    });
  }

  let curScreenState = screenState;
  if (screenState === 'intro'  && introStartedOut) {
    curScreenState = 'input'
  }

  if (isErrorPageVisible) {
    curScreenState = 'error'
  }

  const headerClass = screenState ? `header header--${curScreenState}` : `header`;

  return (
    <header className={headerClass}>
      <a href={resnSiteLink} className="header_resn-logo" target="_blank" rel="noreferrer" onClick={() => trackExternalClick('Resn Drop')}><ResnDrop></ResnDrop></a>

      <div className="header_visit-resn">
        <span className="header_visit-resn_label">{ visitSiteLabel }</span>&nbsp;
        <a href={resnSiteLink} className="header_visit-resn_button" target="_blank" rel="noreferrer" onClick={() => trackExternalClick('Visit Resn')}>{ visitSiteButtonLabel }</a>
        <MuteButton></MuteButton>
      </div>

      <div className="header_contact-resn">
        <span>
          <span
          className="header_contact-resn_label">{contactUsLabel}</span>
          <a href={contactUrl} className="header_contact-resn_button" target="_blank" rel="noreferrer" onClick={() => trackExternalClick('Get in Touch')}>{contactUsButtonLabel}</a>
        </span>
      </div>
    </header>
  )
}

export default HeaderEl
