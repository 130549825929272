import React from 'react'
import './style.scss'
import ReactGA from "react-ga4";
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import CopyButton from "./CopyButton";

import { isMobile } from '../../config/config'
import {
  shareTitle,
  genericSharePrepopulatedCopy,
  twitterSharePrepopulatedCopy,
  genericShareOthersPrepopulatedCopy,
  twitterShareOthersPrepopulatedCopy
} from '../../config/copy'
import {AudioService} from "../../services/audio/AudioService";

const Share = ({ shareURL, imageURL='', viewingGeneratedResult = false, className }) => {


  // const url = window.location.href;
  const url = shareURL; // Local urls don't work with the Share APIs
  const outputText = viewingGeneratedResult ? genericSharePrepopulatedCopy : genericShareOthersPrepopulatedCopy;
  const title = shareTitle;

  /**
   * Returns true if the given URL is external to the current domain.
   * Used to determine if the link should open in a new tab or not.
   * The download attribute doesn't work on external links.
   * @param url
   * @returns {boolean}
   */
  const isExternal = (url) => {
    let host = window.location.host;
    let urlHost;

    try {
      urlHost = new URL(url).host;
    } catch (err) {
      return false;
    }

    return host !== urlHost;
  }

  const DownloadImageButton = ({imageUrl}) => {
    return (
      <a href={imageUrl} download target={isExternal(imageUrl) ? "_blank" : "_self"} rel="noreferrer" onClick={() => handleGAEvent('Download')}>
        Download
      </a>
    );
  }

  const handleGAEvent = (action) => {
    AudioService.playRandomButtonPress()
    // console.log('handleGAEvent', action);
    ReactGA.event({
      category: 'share',
      action: action,
      label: 'Share',
    });
  }

  /**
   * Opens the native share dialog on the current device using the Web Share API.
   *
   * @async
   * @function onNavigatorShare
   * @returns {Promise<void>} - A promise that resolves when the share dialog is closed.
   * @throws {Error} - If there is an error while trying to open the share dialog.
   */
  const onNavigatorShare = async () => {
      try {
        let shareObject = {
          title: title,
          text: outputText, // Remove text for share try so that copy function just copies link
          url: url
        }

        // // can share image, but this then seems to take precidence over the text and link for most shares
        // try {
        //   const imageResponse = await fetch(imageURL);
        //   const imageBlob = await imageResponse.blob();
        //   const file = new File([imageBlob], imageURL, { type: imageBlob.type });

        //   if (navigator.canShare({ files: [file] })) {
        //     shareObject.files = [file];
        //   }
        // } catch (err) {
        //   console.log(err)
        // }

        await navigator.share(shareObject)
        handleGAEvent('native share');
      } catch (err) {
        console.log(err)
      }
  }

  /**
   * Returns true if the Web Share API is supported on the current device.
   * @returns {boolean}
   */
  const hasNavigatorShare = () => {
    return isMobile && (typeof navigator !== 'undefined' && 'share' in navigator);
  }

  const renderNative = () => {
    return <>
      <button
        id="share-native"
        className="share-btn web-share"
        onClick={() => onNavigatorShare()}>
        Share
      </button>
    </>;
  }

  const renderFallback = () => {

    return <>
      <CopyButton url={url} />
      <TwitterShareButton
        onClick={() => handleGAEvent('Twitter')}
        url={url}
        title={viewingGeneratedResult ? twitterSharePrepopulatedCopy : twitterShareOthersPrepopulatedCopy}
        style={{ font: null, color: null }}
        className="share--hidemobile"
      >
        Xwitter
      </TwitterShareButton>
      <FacebookShareButton
        onClick={() => handleGAEvent('facebook')}
        url={url}
        quote={outputText}
        style={{ font: null, color: null}}
        className="share--hidemobile"
      >
        Facebook
      </FacebookShareButton>
    </>;
  }

  const modifierClassName = className ? "share " + className : 'share';

  return (
    <div className={modifierClassName}>
      { hasNavigatorShare() ? renderNative() : renderFallback() }
      <DownloadImageButton imageUrl={imageURL} />
    </div>
  )
}

export default Share
