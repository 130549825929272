import React, { useState, useEffect, useRef } from 'react'
import Disclaimer from "../Disclaimer";
import Share from "../Share";
import ErrorPage from "../ErrorPage";

import Intro from '../Intro'

import HeaderEl from '../HeaderEl'
import Footer from '../Footer'

import ImageInput from  '../ImageInput'
import MessageInput from '../MessageInput'
// import WebGLView from '../WebGLView'

import ErrorMessage from '../ErrorMessage'

import GeneratingIndicator from '../GeneratingIndicator'
import GenericLoader from '../GenericLoader'

import MessageOutput from '../MessageOutput'
import Button from '../Button'
import RecommendedBooks from '../RecommendedBooks'

import useGenerationRequest from '../../hooks/useGenerationRequest'

import './style.scss' // add after imports to apply overriding styles
import './theme.scss' // add after imports to apply overriding styles


import { debounce, getParameterByName, getPathValueFromUrl } from '../../utils/helpers'

import {
  randomizeSenderButtonLabel,
  randomizeReceiverButtonLabel,
  subjectALabel,
  subjectBLabel,
  subjectADescLabel,
  subjectBDescLabel,
  generateAnotherLabel,
  generateYourOwnLabel,
  nextButtonLabel,
  enterButtonLabel,
  serverErrorMessage,
  contentPolicyErrorMessage,
  shareDataError,
  serverTooBusyErrorMessage,
  tooManyTriesErrorMessage,
  placeholderItems,
  senderHeadline,
  receiverHeadline,
  senderNamePlaceholder,
  senderDescPlaceholder,
  receiverNamePlaceholder,
  receiverDescPlaceholder,
  senderRandomiseLabel,
  receiverRandomiseLabel
} from '../../config/copy'

import {pregenerated_input} from '../../config/pregenerated_input'

import { siteRoot, siteRootProduction, resultsFolder, resultsThumbsFolder, ttsAudioExtension, siteShareRoot, siteRootBase, introDuration, apiEndpoint, numRecommendations, ttsAudioDelay } from '../../config/config'

import { ReactComponent as Heart } from '../../assets/and.svg'


import CoverOverlay from '../../assets/img/cover-overlay.png';
import CoverOverlayTexture from '../../assets/img/cover-overlay-texture.png';

import ReactGA from "react-ga4";
import {checkLimit, checkServerRateLimit} from '../../utils/attempts.js';

import {AudioService, AUDIO_URLS} from "../../services/audio/AudioService";


let randomizeInputAIndex = 0
let randomizeInputBIndex = 0

function App() {
  const shareUrlId = getPathValueFromUrl('/share/') || getParameterByName('share') || null

  const skipIntro = window.location.search.includes('skip')
  const allowExtra = window.location.search.includes('extra')
  const playTTS = true // window.location.search.includes('tts')

  const [screenState, setScreenState] = useState('intro') // skipIntro ? 'input' : 'intro')

  //sender fields
  // const [placeholderIndexSender, setPlaceholderIndexSender] = useState(0) //index for placeholder cycling animation
  // const [usePlaceHolderImgSender, setUsePlaceHolderImgSender] = useState(0) //index for set random text on button click
  const [subjectAInput, setSubjectAInput] = useState('')
  // const [subjectAImageInput, setSubjectAImageInput] = useState('')
  const [subjectADescInput, setSubjectADescInput] = useState('')


  //receiver  fields
  // const [placeholderIndexReceiver, setPlaceholderIndexReceiver] = useState(0) //index for placeholder cycling animation
  // const [usePlaceHolderImgReceiver, setUsePlaceHolderImgReceiver] = useState(0) //index for set random text on button click
  const [subjectBInput, setSubjectBInput] = useState('')
  // const [subjectBImageInput, setSubjectBImageInput] = useState('')
  const [subjectBDescInput, setSubjectBDescInput] = useState('')


  // useEffect(()=>{
  //   console.log('subjectAImageInput', subjectAImageInput)
  // },[subjectAImageInput])
  // useEffect(()=>{
  //   console.log('subjectBImageInput', subjectBImageInput)
  // },[subjectBImageInput])

  const [shareUrl, setShareUrl] = useState('')
  const [viewingGeneratedResult, setViewingGeneratedResult] = useState(false)

  const [curTTSAudioUrl, setCurTTSAudioUrl] = useState(null)

  const [isErrorPageVisible, setIsErrorPageVisible] = useState(false);


  // const [messageRequest, setMessageRequest] = useState('')



  const [isLoading, setIsLoading] = useState(false)

  const [introStartedOut, setIntroStartedOut] = useState(false)

  const getFullCoverImageUrl = (resultId, imageUrl, loadFromProduction) => {
    // if curCoverImageUrl doesn't start with http
    if (imageUrl && imageUrl.indexOf('http') !== 0 && imageUrl.indexOf('/') !== 0) {
      // prepend site root

      const curSiteRoot = loadFromProduction ? siteRootProduction : siteRoot;

      if (imageUrl.includes(resultId + '/')) {
        imageUrl = curSiteRoot + '/' + resultsFolder + '/' + imageUrl;
      } else {
        imageUrl = curSiteRoot + '/' + resultsFolder + '/' + resultId + '/' + imageUrl;
      }
    }
    return imageUrl;
  }

  const getAudioUrl = (resultId, audioUrl, loadFromProduction) => {
    const curSiteRoot = loadFromProduction ? siteRootProduction : siteRoot;

    audioUrl = curSiteRoot + '/' + resultsFolder + '/' + resultId + '/' + audioUrl + '.' + ttsAudioExtension;
    return audioUrl;
  }

  const [bookItems, setBookItems] = useState([])
  const componentMounted = useRef(false)

  const [hideName, setHideName] = useState(false)
  const [shareId, setShareId] = useState(shareUrlId || null)
  const [bookColor, setBookColor] = useState(null)

  const [isViewingShareCard, setIsViewingShareCard] = useState(false)

  const [error, setError] = useState(null)
  const [errorCode, setErrorCode] = useState(null)

  const [outputText, setOutputText] = useState('')
  const [curResultId, setCurResultId] = useState(null)
  const [responseData, setResponseData] = useState(null)

  // const [imageUrl, setImageUrl] = useState('')

  const [titleText, setTitleText] = useState('')
  // const [authorText, setAuthorText] = useState('')
  const [taglineText, setTaglineText] = useState('')
  // const [subjectsText, setSubjectsText] = useState('')

  const [placeholderItemsSetA, setPlaceholderItemsSetA] = useState(null)
  const [placeholderItemsSetB, setPlaceholderItemsSetB] = useState(null)

  // const [imageDataUrl, setImageDataUrl] = useState(null);
  const [coverImageDataUrl, setCoverImageDataUrl] = useState(null);

  const [hasSenderInput, setHasSenderInput] = useState(false)
  const [hasReceiverInput, setHasReceiverInput] = useState(false)

  const subjectAInputRef = useRef(null)
  // const subjectAImageInputRef = useRef(null)
  const subjectADescInputRef = useRef(null)

  const subjectBInputRef = useRef(null)
  // const subjectBImageInputRef = useRef(null)
  const subjectBDescInputRef = useRef(null)

  const [ hasReachedLimit, setHasReachedLimit] = useState(false);


  /**
   * Map screen state to a route name for analytics
   * @type {{input: string, displayOutput: string, intro: string, generating: string}}
   */
  const routesNames = {
    'intro': 'intro',
    'inputSender': 'home',
    'inputReceiver': 'inputReceiver',
    'generating': 'generating',
    'displayOutput': 'result',
    'disclaimer': 'disclaimer'
  };

  /**
   * Get route name from screen state, if it's been defined in routesNames object
   * @param screenState
   * @returns {*}
   */
  const getRouteName = (screenState) => {
    return routesNames.hasOwnProperty(screenState) ? routesNames[screenState] : screenState
  }

  /**
   * Send a pageview event for every screen state change
   */
  const handleAnalytics = () => {
    ReactGA.send({
      hitType: "pageview",
      page: screenState,
      title: getRouteName(screenState)
    });
  }

  // let interval
  // const startPlaceholderTick = ()=> {
  //   clearInterval(interval)
  //   interval = setInterval(() => {
  //     let len = Math.floor(pregenerated_input.length / 2)
  //     setPlaceholderIndexSender(prev => (prev + 1) % len)
  //     setPlaceholderIndexReceiver(prev => (prev + 1) % len)
  //   }, 5000)
  // }

  const start = function() {
    // startPlaceholderTick()
    AudioService.playRandomButtonPress()
    if (shareId) {
      setScreenState('displayOutput')
    } else {
      setScreenState('inputSender')
    }
  }

  const next = function() {
    setError(null)
    setScreenState('inputReceiver')
  }

  const disclaimer = function() {
    setScreenState('disclaimer')
  }

  /**
   *
   * @returns {boolean}
   */
  const showErrorPage = function() {
    const errorPageStates = ['inputSender', 'inputReceiver', 'generating']; // states where error page might be shown instead of the main content
    return errorPageStates.includes(screenState)
      && errorCode !== null
      && isErrorCodePage(errorCode);
  }

  const handleRandomizeInputA = function() {
    AudioService.playRandomButtonPress()
    //if no input, use the current placeholder as input, otherwise increment
    let hasInput = subjectAInput.trim().length > 0
    const len = placeholderItemsSetA?.length || 0

    const next = (randomizeInputAIndex+1) % (len)
    randomizeInputAIndex  = next
    // randomizeInputAIndex  = hasInput ? next : placeholderIndexSender
    //console.log('subjectAInput.trim()',  hasInput, randomizeInputAIndex, next, len, placeholderIndexSender)
    const sender = placeholderItemsSetA[randomizeInputAIndex]

    setSubjectAInput(sender.title)
    setSubjectADescInput(sender.description)
    // const imageURL = `${process.env.PUBLIC_URL}/random_input/s512/${sender.image}`
    // subjectAImageInputRef.current.setRandomImage(imageURL)

    ReactGA.event({
      category: 'Buttons',
      action: "randomize sender",
      label: 'randomize',
    });
  }

  const handleRandomizeInputB = function() {
    AudioService.playRandomButtonPress()
    //if no input, use the current placeholder as input, otherwise increment
    let hasInput = subjectBInput.trim().length > 0
    const len = placeholderItemsSetB?.length || 0

    const next = (randomizeInputBIndex+1) % (len)
    randomizeInputBIndex  = next
    // randomizeInputBIndex  = hasInput ? next : placeholderIndexReceiver
    const sender = placeholderItemsSetB[randomizeInputBIndex]

    setSubjectBInput(sender.title)
    setSubjectBDescInput(sender.description)
    // const imageURL = `${process.env.PUBLIC_URL}/random_input/s512/${sender.image}`
    // subjectBImageInputRef.current.setRandomImage(imageURL)

    ReactGA.event({
      category: 'Buttons',
      action: "randomize receiver",
      label: 'randomize',
    });
  }

  const loadRecommendations = function(newShareId = null) {
    const currentResultId = newShareId || shareId || curResultId || ''
    const requestBody = {
      action: 'getRecommendations',
      count: numRecommendations,
      id: currentResultId
    }
    if (window.location.search.includes('extra')) {
      requestBody.extra = true
    }
    fetch(apiEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    })
    .then(response => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    })
    .then(recommendations => {

      let newBookItems = []
      for (let i = 0; i < recommendations.length && i < numRecommendations; i++) {
        const bookId = recommendations[i].id
        const bookThumbUrl = siteRoot + '/' + resultsThumbsFolder + '/' + bookId + '.jpg'
        const newBookItem = {
          id: bookId,
          imageUrl: getFullCoverImageUrl(bookId, bookThumbUrl),
        }
        newBookItems.push(newBookItem)
      }
      setBookItems(newBookItems)

    }).catch(error => {
      if(error.status === 429) {
        setError(serverTooBusyErrorMessage);
        setErrorCode(error.status);
        return;
      }
      setError(serverErrorMessage);
    });
  }

  const loadShareResult = function(newShareId, loadFromProduction) {
    // load json file into response data from path with shareId
    const curSiteRoot = loadFromProduction ? siteRootProduction : siteRoot;
    const url = curSiteRoot + '/' + resultsFolder + '/' + newShareId + '/data.json';
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then(data => {
        data.isShareData = true;
        data.loadFromProduction = loadFromProduction;
        setResponseData(data)
        if (debugMode) {
          console.log('share data', data);
        }
      })
      .catch(error => {
        if(error.status === 429) {
          setError(serverTooBusyErrorMessage);
          setErrorCode(error.status);
          return;
        }
        setError(shareDataError)
        // setErrorCode(404); // don't jump to 404 but display can't find car message on input screen so user can continue
        setShareId(null)
        setScreenState('inputSender') // if we can't get the share data, go back to input so we can display the error page
      });
  }

  // Helper function to check if error is handled
  const isErrorCodePage = (errorCode) => {
    const handledErrorCodes = [500, 429, 404, 403, 400]; // Define the handled error codes
    return handledErrorCodes.includes(errorCode);
  };

  const retry = function() {
    ReactGA.event({
      category: 'Buttons',
      action: "create another",
      label: 'create another',
    });

    setOutputText('')
    setResponseData(null)

    setError(null)
    setInviteMode(false)

    setShareId(null)
    setBookColor(null)

    setScreenState('inputSender')

    // setHideName(true)

    window.history.pushState({}, '', '/' + siteRootBase + '/' + window.location.search);

    setSubjectAInput('')
    setSubjectADescInput('')

    setSubjectBInput('')
    setSubjectBDescInput('')
  }

  const selectBook = function(bookItem) {
    AudioService.playRandomButtonPress()
    setShareId(null)
    setScreenState('loadingBook')

    loadShareResult(bookItem.id, true)

    loadRecommendations(bookItem.id)
  }

  const debugMode = window.location.search.includes('debug')

  const storyMode = window.location.search.includes('story') // invite mode off by default

  const initialInviteMode = window.location.search.includes('invite') // invite mode off by default
  // const inviteMode = !window.location.search.includes('to') // invite mode on by default

  const [inviteMode, setInviteMode] = useState(initialInviteMode)

  const inviteName = getParameterByName('invite') || ''
  const storyName = getParameterByName('story') || ''

  const recipientName = getParameterByName('to') || '' // for non-invites

  // get properties from useChat hook
  const {
    // debugText,
    sendMessage,
    // messages,
  } = useGenerationRequest(subjectAInput, subjectBInput, subjectADescInput, subjectBDescInput, setResponseData, setIsLoading, setError, debugMode, allowExtra)

  useEffect(() => {
    if (screenState !== 'result') {
      AudioService.stopTTSAudio()
    }

    switch (screenState) {
      case 'inputSender':
        setPlaceholderItems();
        break;
      case 'displayOutput':
        if (curTTSAudioUrl && playTTS) {
          AudioService.playTTSAudio(curTTSAudioUrl, ttsAudioDelay)
        }
    }

    // set current track
    switch (screenState) {
      case 'intro':
        // Not playing mainThemeMusic on share intro page as after pressting start it goes into the results music instantly
        if(!shareId){
          AudioService.playBackgroundLoop(AUDIO_URLS.mainThemeMusic, 250, 250)
        }
        break
      case 'inputSender':
        AudioService.playBackgroundLoop(AUDIO_URLS.mainThemeMusic, 250, 250)
        break;
      case 'generating':
        AudioService.playBackgroundLoop(AUDIO_URLS.generatingMusic, 100, 4000, 0.3)
        break;
      case 'displayOutput':
        AudioService.playBackgroundLoop(AUDIO_URLS.resultsMusic, 250, 250, 0.35)
        break;
    }
  }, [screenState])

  useEffect(() => {
    switch (screenState) {
      case 'inputSender':
        if (hasReachedLimit && error !== shareDataError) {
          setError(tooManyTriesErrorMessage);
        }
        break;
      default:
        break;
    }
  }, [hasReachedLimit])

  // UseEffect Hook to handle ErrorCode Changes
  useEffect(() => {
    if (!isErrorCodePage(errorCode)) {
      setErrorCode(null)
    }
  }, [errorCode]);

  // UseEffect Hook to handle ErrorCode Changes
  useEffect(() => {
    setIsViewingShareCard(!!shareId) // set viewing card to true if share id is set
  }, [shareId]);

  const setPlaceholderItems = () => {
    // create ranomdised copy ofplaceholderItems
    let randomisedPlaceholderItems = [...pregenerated_input].sort(() => Math.random() - 0.5)


    // split list
    let sliceIndex = Math.floor(randomisedPlaceholderItems.length / 2)
    let newPlaceholderItemsSetA = randomisedPlaceholderItems.slice(0, sliceIndex)
    let newPlaceholderItemsSetB = randomisedPlaceholderItems.slice(sliceIndex)

    setPlaceholderItemsSetA(newPlaceholderItemsSetA)
    setPlaceholderItemsSetB(newPlaceholderItemsSetB)
  }

  // set name if set with invite or recipient name url parameters
  useEffect(() => {
    if (inviteName && inviteName.length) {
      //captialise first letter
      const capitalisedName = inviteName.charAt(0).toUpperCase() + inviteName.slice(1)
      setSubjectAInput(capitalisedName)

      // setHideName(true)
    } else if (recipientName && recipientName.length) {
      //captialise first letter
      const capitalisedName = recipientName.charAt(0).toUpperCase() + recipientName.slice(1)
      setSubjectAInput(capitalisedName)

      // setHideName(true)
    } else if (storyName && storyName.length) {
      //captialise first letter
      const capitalisedName = storyName.charAt(0).toUpperCase() + storyName.slice(1)
      setSubjectAInput(capitalisedName)

      // setHideName(true)
    } else {
      // setHideName(false)
    }
  }, [inviteName, recipientName])

  // // on input changed
  // useEffect(() => {
  //   subjectBInputRef.current = subjectBInput // store input in ref to ensure latest value is used in delayed send button activate debounce
  // }, [subjectBInput])

  // on load
  useEffect( () => {
    // if component mounted is not set
    if (!componentMounted.current) {
      // set component mounted
      componentMounted.current = true

      if (window.location.pathname.includes('/disclaimer')) {
        disclaimer();
      } else if (shareId) {
        loadShareResult(shareId);
      } else {

        if (introDuration >= 0 && !skipIntro)
        {
        //   setTimeout(() => {
        //     start()
        //   }, introDuration * 1000)
        } else {
          start()
        }
      }

      loadRecommendations()
    }
  }, [])

  // on message input changed
  const handleInputSenderChange = () => {
    let newHasInput = subjectAInput.trim().length > 0
    if (newHasInput) {
      // if input has text
      debounce(() => {
        // debounce to add delay to send button activation
        // const currentInput = subjectBInputRef.current
        const currentHasInput = subjectAInput.trim().length > 0
        setHasSenderInput(currentHasInput) // activate send button if input still has text
      }, 250)()
    } else {
      // if input is empty
      setHasSenderInput(newHasInput) // deactivate send button immediately
    }
  }

  // on message input changed
  const handleInputReceiverChange = () => {
    let newHasInput = subjectBInput.trim().length > 0
    if (newHasInput) {
      // if input has text
      debounce(() => {
        // debounce to add delay to send button activation
        // const currentInput = subjectBInputRef.current
        const currentHasInput = subjectBInput.trim().length > 0
        setHasReceiverInput(currentHasInput) // activate send button if input still has text
      }, 250)()
    } else {
      // if input is empty
      setHasReceiverInput(newHasInput) // deactivate send button immediately
    }
  }

  // on inputs changed
  useEffect(() => {
    handleInputSenderChange() // handle message subjectAInput change for activating next button
  }, [subjectAInput])


  // on inputs changed
  useEffect(() => {
    handleInputReceiverChange() // handle message subjectBInput change for activating send button
  }, [subjectBInput])


  const focusSubjectADescInput = event => {
    subjectADescInputRef.current.focus()
  }
  const focusSubjectBDescInput = event => {
    subjectBDescInputRef.current.focus()
  }
  // const focusNextInput = event => {
  //   subjectBInputRef.current.focus()
  // }

  const handleInputFocus = () => {

    switch (screenState) {
      // case 'input':
      //   if (subjectAInput) {
      //     if (!error) { // if there is no error
      //       // subjectBInputRef.current.focusAndSelect()
      //       subjectAInputRef.current.focus()
      //     } else {
      //       subjectBInputRef.current.focus()
      //     }
      //   } else {
      //     // don't focus to allow user to see examples
      //     // if (!hideName && subjectAInputRef.current) {
      //     //   subjectAInputRef.current.focus()
      //     // }
      //   }
      //   break
      default:
        break
    }
  }

  /**
   * Handles attempts based on the current screen state.
   * - increment the tries if we are on the displayOutput screen.
   * - check the limit if we are on the input or displayOutput screen. This accounts
   * for the case where the user has reached the limit and then refreshes the page,
   * as well as the results page itself.
   *
   * @returns {void}
   */
  const handleAttempts = async () => {
    if (['inputSender','displayOutput' ].includes(screenState)) {
      setHasReachedLimit(checkLimit() && !allowExtra);
    }

    const limitPromise = checkServerRateLimit()

    limitPromise.then((limit) => {
      if (limit && errorCode !== 429) {
        setErrorCode(429)
        setError(serverTooBusyErrorMessage)
      }
    }).catch(() => {
      setError(serverErrorMessage);
    });
  }

  /**
   * Handle changing Screenstate
   */
  useEffect(() => {
    handleAnalytics();
    handleInputFocus();
    handleAttempts();
  }, [screenState, hideName])

  // handle sending message and hiding conversations logged warning
  const handleNext = () => {
    AudioService.playRandomButtonPress()

    if (subjectAInput) {
      if (!checkLimit() || allowExtra) {
        next()
      } else {
        setError(null)

        setTimeout(() => {
          setError(tooManyTriesErrorMessage)
        }, 100)
      }
    }
  }

  // handle sending message and hiding conversations logged warning
  const handleSubmit = () => {
    AudioService.playItsTime()
    if (subjectBInput) {
      if (!checkLimit() || allowExtra) {
        sendMessage()
      } else {
        setError(null)

        setTimeout(() => {
          setError(tooManyTriesErrorMessage)
        }, 100)
      }
    }
  }

  // go back to input sender state on error
  useEffect(() => {
    if (error && !isErrorCodePage(errorCode)) {
      setShareId(null) // clear share id so no longer in shared state
      setScreenState('inputSender')
    }
  }, [error])



  // go to displayOutput state when output text is set
  useEffect(() => {
    if (!responseData) {
      return;
    }

    const isMissingData = !responseData.id
      || !responseData.title
      || !responseData.tagline
      || !responseData.poem
      || !responseData.subjects
      || !responseData.coverImageUrl;

    if (isMissingData) {
      const missingKeys = ["id", "tagline", "title", "poem", "subjects", "coverImageUrl"].filter(key => !responseData[key])
      if (debugMode) {
        console.error('Missing response data:', missingKeys)
      }
      if (responseData.isShareData) {
        setError(shareDataError)
      } else {
        if (responseData.tagline === ''
        && responseData.subjects === ''
        && responseData.poem === '') {
          setError(contentPolicyErrorMessage) // if no data, likely gpt response from inappropriate content
        } else {
          setError(serverErrorMessage)
        }
      }
      return;
    }

    setViewingGeneratedResult(responseData.isUserResult)

    setTitleText(responseData.title || "")
    // setAuthorText(responseData.author || "")
    setTaglineText(responseData.tagline || "")
    // setSubjectsText(responseData.subjects || "")
    setOutputText(responseData.poem || "")
    setBookColor(responseData.color || null)

    let resultId = responseData.id;
    setCurResultId(resultId);

    let curCoverImageUrl = getFullCoverImageUrl(resultId, responseData.coverImageUrl, responseData.loadFromProduction)

    const coverImage = new Image();
    coverImage.onload = () => {
      setCoverImageDataUrl(curCoverImageUrl);
      if (!shareId) { // if loading a shared book then wait till user hits start button
        setScreenState('displayOutput');
      }
    };
    coverImage.onerror = (error) => {
      if (debugMode) {
        console.error('error loading cover image', error);
      }
      setError(serverErrorMessage)
    };
    coverImage.src = curCoverImageUrl;

    if (responseData.audioUrl && playTTS) {
      const newTTSAudioUrl = getAudioUrl(resultId, responseData.audioUrl, responseData.loadFromProduction)
      AudioService.loadTTSAudio(newTTSAudioUrl)
      setCurTTSAudioUrl(newTTSAudioUrl)
    } else {
      setCurTTSAudioUrl(null)
    }

    if (resultId && resultId !== 'test') {
      setSharePath('/share/' + resultId);
    }

  }, [responseData])

  const setSharePath = (sharePath) => {
    window.history.pushState({}, '', '/' + siteRootBase + sharePath + window.location.search);
    let newSiteShareUrl = siteShareRoot + sharePath;
    if (newSiteShareUrl.indexOf('http') !== 0) {
      newSiteShareUrl = 'https://' + newSiteShareUrl;
    }
    setShareUrl(newSiteShareUrl);
  }

  // go to generating
  useEffect(() => {
    if (isLoading) {
      setScreenState('generating')
    }
  }, [isLoading])

  // Using an effect to check for URL change

  useEffect(() => {
    if (window.location.pathname.includes('/disclaimer')) {
      setScreenState('disclaimer');  // set the state to 'disclaimer'
    }
  }, []);


  const getInputSenderScreen = () => {
    return <>
      { showErrorPage() ? <ErrorPage errorCode={errorCode} setIsErrorVisible={setIsErrorPageVisible} /> : <div className="input-screen">
        <div className="input-screen_pane">

          <div className="input-screen_headline">
            {error ? <ErrorMessage
              errorMessage={error}
            /> : null}
            {senderHeadline}
          </div>
          <div className="input-screen_label">{subjectALabel}</div>
          <MessageInput
            input={subjectAInput}
            // placeholderIndex={placeholderIndexSender}
            // placeholderItems={placeholderItemsSetA}
            placeholderText={senderNamePlaceholder}
            placeholderKey={'title'}
            onInputChange={value => {
              setSubjectAInput(value)
            }}
            onEnterKeyDown={focusSubjectADescInput}
            ref={subjectAInputRef}
          />

            {/* <ImageInput
              onFileChange={value => setSubjectAImageInput(value)}
              ref={subjectAImageInputRef}
            /> */}

      <div className="input-screen_label input-screen_label_desc">{ subjectADescLabel }</div>
          <div className="input-screen_optional">optional</div>
          <MessageInput
        className={'input_desc'}
            input={subjectADescInput}
            // placeholderIndex={placeholderIndexSender}
            // placeholderItems={placeholderItemsSetA}
            placeholderText={senderDescPlaceholder}
            placeholderKey={'description'}
            onInputChange={value => {
              setSubjectADescInput(value)
            }}
            onEnterKeyDown={handleNext}
            allowAutoHeight={true}
            ref={subjectADescInputRef}
          />

      <div className="input-screen_label input-screen_label_randomize">{senderRandomiseLabel}</div>
          <div className="container randomize-wrap">
            <button
              className={`randomize-button`}
              onClick={handleRandomizeInputA}
            >
              {randomizeSenderButtonLabel}
            </button>
          </div>

          {/* <div className="app_heart app_and"><Heart></Heart></div> */}
          <div className="app_enter-button-container">
              <button
                className={`enter-button`}
                onClick={handleNext}
                data-has-input={hasSenderInput ? true : null}
              >
                {nextButtonLabel}
              </button>
          </div>
        </div>
      </div>}
    </>
  }

  const getInputReceiverScreen = () => {
    return  <>
      { showErrorPage() ? <ErrorPage errorCode={errorCode} setIsErrorVisible={setIsErrorPageVisible} /> : <div className="input-screen">
        <div className="input-screen_pane">

          <div className="input-screen_headline">
            {error ? <ErrorMessage
              errorMessage={error}
            /> : null}
            {receiverHeadline}
          </div>
          <div className="input-screen_label">{subjectBLabel}</div>
          <MessageInput
            input={subjectBInput}
            // placeholderIndex={placeholderIndexReceiver}
            // placeholderItems={placeholderItemsSetB}
            placeholderText={receiverNamePlaceholder}
            placeholderKey={'title'}
            carouselDelayOffset={0.5}
            onInputChange={value => {
              setSubjectBInput(value)
            }}
            onEnterKeyDown={focusSubjectBDescInput}
            ref={subjectBInputRef}
          />

            {/* <ImageInput
              onFileChange={value => setSubjectBImageInput(value)}
              ref={subjectBImageInputRef}
            /> */}

        <div className="input-screen_label input-screen_label_desc">{ subjectBDescLabel }</div>
          <div className="input-screen_optional">optional</div>
          <MessageInput
          className={'input_desc'}
            input={subjectBDescInput}
            // placeholderIndex={placeholderIndexSender}
            // placeholderItems={placeholderItemsSetB}
            placeholderText={receiverDescPlaceholder}
            placeholderKey={'description'}
            onInputChange={value => {
              setSubjectBDescInput(value)
            }}
            allowAutoHeight={true}
            hasSubmit={true}
            onSend={handleSubmit}
            ref={subjectBDescInputRef}
          />

        <div className="input-screen_label input-screen_label_randomize">{receiverRandomiseLabel}</div>
          <div className="container randomize-wrap">
            <button
              className={`randomize-button`}
              onClick={handleRandomizeInputB}
            >
              {randomizeReceiverButtonLabel}
            </button>
          </div>

          <div className="app_enter-button-container">
            <button
              className={`enter-button`}
              onClick={handleSubmit}
              data-has-input={hasReceiverInput ? true : null}
            >
              {enterButtonLabel}
            </button>
          </div>
        </div>
      </div>}
    </>
  }

  const getResult = () => {
    return <div className="output-screen">
      <div className='output-screen__block-container'>
        <div className='output-screen__block output-screen__block-left'>
          <div className='output-screen__block-content output-screen__block-content-left'>
            <div className='output-screen__cover-container'>
              <div className='output-screen__cover-container-inner'>
                <div className='output-screen__cover-content-container'>
                  {/* {imageDataUrl && (
                    <img className='output-screen__cover-content-image' src={imageDataUrl} alt=""/>
                  )} */}
                  <img src={coverImageDataUrl} className='output-screen__cover-overlay-container-cover' alt=""/>
                </div>
                <div className='output-screen__cover-overlay-container output-screen__cover-overlay-container-components'>
                  <img src={CoverOverlay} alt=""/>
                  {/* <div className='output-screen__cover-overlay-text output-screen__cover-overlay-title'>{titleText}</div>
                  <div
                    className='output-screen__cover-overlay-text output-screen__cover-overlay-author'>by {authorText}</div>
                  <div
                    className='output-screen__cover-overlay-text output-screen__cover-overlay-tagline'>{taglineText}</div>
                  <div
                    className='output-screen__cover-overlay-text output-screen__cover-overlay-starring'>Starring {subjectsText}</div> */}
                  <img src={CoverOverlayTexture} className='output-screen__cover-overlay-container-texture' alt=""/>
                </div>
              </div>
            </div>
          </div>
          {coverImageDataUrl && (<Share shareURL={shareUrl} imageURL={coverImageDataUrl} viewingGeneratedResult={viewingGeneratedResult} className="share--mobile" />)}
        </div>
        <div className='output-screen__block output-screen__block-right'>
          <div className='output-screen__block-content output-screen__block-content--text'>
            <div className='output-screen__right-spacer'></div>
            <div className='output-screen__right-wrap'>
              <MessageOutput
                titleText={titleText}
                outputText={outputText}
              />
              {hasReachedLimit || errorCode ? '' :
                <div className='output-screen__rsvp-option-container rsvp-container'><Button
                  buttonLabel={shareId ? generateYourOwnLabel : generateAnotherLabel}
                  className="output-screen__rsvp-option yes"
                  onClick={() => retry()}
                /></div>
              }
            </div>
            { bookItems && bookItems.length ? <RecommendedBooks bookItems={bookItems} selectBook={selectBook} /> : <div className="output-screen__footer-spacer" /> }
          </div>
        </div>
      </div>
    </div>;
  }

  // Check if the screenState is disclaimer
  if (screenState === 'disclaimer') {
    return <Disclaimer screenState={screenState} coverImageDataUrl={coverImageDataUrl} shareUrl={shareUrl} />
  }

  return (
    <div className="app">
      {/* <WebGLView></WebGLView> */}

      <div className={`page-content ${bookColor ? 'book-theme-' + bookColor : ''} ${'page-screen_' + screenState}`}>
        {/* {debugMode ? <div className="debug-text">{debugText}</div> : null} */}
        { screenState === 'intro' && <div className="intro-screen">
          <Intro setIntroStartedOut={setIntroStartedOut} onStart={start} isViewingShareCard={isViewingShareCard} />
        </div> }


          {/* Inputs */}
          { screenState === 'inputSender' && getInputSenderScreen()}

          {/* Inputs */}
          { screenState === 'inputReceiver' && getInputReceiverScreen()}

          {/* Generating indicator */}
          { screenState === 'generating' && <GeneratingIndicator />}

          {/* Output screen */}
          { screenState === 'displayOutput' && getResult() }

          {/* Book loader */}
          { screenState === 'loadingBook' && <GenericLoader />}


        <HeaderEl screenState={screenState} introStartedOut={introStartedOut} isErrorPageVisible={isErrorPageVisible} />
        <Footer screenState={screenState} coverImageDataUrl={coverImageDataUrl} shareUrl={shareUrl} isErrorPageVisible={isErrorPageVisible} viewingGeneratedResult={viewingGeneratedResult} />

      </div>
    </div>
  )
}

export default App
