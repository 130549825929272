import React from 'react'
import './style.scss'
import Share from "../Share";

import { siteRootBase,  } from '../../config/config'

import { disclaimerButtonLabel, cookieButtonLabel } from '../../config/copy'
import {AudioService} from "../../services/audio/AudioService";

const Footer = ({screenState, coverImageDataUrl, shareUrl, isErrorPageVisible, viewingGeneratedResult }) => {

  /*    'inputSender': 'home',
    'inputReceiver': 'inputReceiver',*/

  const isOnDisclaimerPage = screenState === 'disclaimer'
  const isInputPage = ['inputReceiver', 'home','inputSender'].includes(screenState)
  const isResult = screenState === 'displayOutput'
  const showShare = isResult && coverImageDataUrl

  let curScreenState = screenState;

  if (isErrorPageVisible) {
    curScreenState = 'error'
  }

  const footerClass = screenState ? `footer footer--${curScreenState}` : `footer`;


  function onCookieClick(){
    AudioService.playRandomButtonPress()
    window.Cookiebot?.show()
  }


  return (
    <footer className={footerClass}>
      {showShare && (<Share className="share--desktop" shareURL={shareUrl} imageURL={coverImageDataUrl} viewingGeneratedResult={viewingGeneratedResult}/>)}
      <div className="footer__wrap">
        <div className="footer__left">
          {!isOnDisclaimerPage ? <a className="footer_disclaimer-button"
                                    onClick={() => AudioService.playRandomButtonPress()}
                                    href={"/" + siteRootBase + "/disclaimer"}>{disclaimerButtonLabel}</a> : ''}
        </div>

        <div className="footer__right">
          {/* {isInputPage ? <div className="footer_share-disclaimer" >{shareDisclaimer}</div> : ''} */}
          <button className="footer_cookies-button"
             onClick={onCookieClick}
             href={"/" + siteRootBase + "/cookies"}>{cookieButtonLabel}</button>
        </div>


      </div>

    </footer>
  )
}

export default Footer
