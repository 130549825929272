import React, { useRef, useState, useEffect } from 'react'
import './style.scss'

import logo_hero from '../../assets/img/logo/logo_hero.webp'// relative path to image
import logo_hearts from '../../assets/img/logo/logo_hearts.webp'// relative path to image
import logo_stars_blue from '../../assets/img/logo/logo_stars_blue.webp'// relative path to image
import logo_stars_red from '../../assets/img/logo/logo_stars_red.webp'// relative path to image
import logo_subtitle from '../../assets/img/logo/logo_subtitle.webp'// relative path to image
import logo_title from '../../assets/img/logo/logo_title.webp'// relative path to image
import logo_twinkles from '../../assets/img/logo/logo_twinkles.webp'// relative path to image

import gsap from 'gsap'

import {
  startButtonLabel,
  viewCardButtonLabel,
} from '../../config/copy'
import {preloadImages} from "../../services/util/loaders";

const skipIntro = window.location.search.includes('skip')

const imageSrcs = [
logo_hero,
logo_hearts,
logo_stars_blue,
logo_stars_red,
logo_subtitle,
logo_title,
logo_twinkles,
]

let imageCache = []

const Intro = ({ setIntroStartedOut, onStart, isViewingShareCard }) => {

  const heartsRef = useRef(null)
  const starBlueRef = useRef(null)
  const starRedRef = useRef(null)
  const heroRef = useRef(null)
  const twinklesRef = useRef(null)
  const subtitleRef = useRef(null)
  const titleRef = useRef(null)
  const startRef = useRef(null)

  const [componentMounted, setComponentMounted] = useState(false)

  const [introComplete, setIntroComplete] = useState(false)

  // animate out state
  const [animateOut, setAnimateOut, isViewingCard] = useState(false)

  async function animateIn(){
    setIntroComplete(false)

    imageCache = await preloadImages(imageSrcs)

    const startEl = startRef.current

    const introDelay = 0.4;
    const logoAnimDuration = 8;
    const logoAnimEase = 'expo.out';
    const titleDelay = 0.8;
    const titleDuration = 4;
    const subTitleDelay = titleDelay + 1.25;
    const startButtonDelay = subTitleDelay + 0.75;

    const itemSettings = [
      {
        item: heroRef.current,
        scale: 0.8,
        delay: 0,
        duration: logoAnimDuration,
        ease: logoAnimEase,
      },
      {
        item: starRedRef.current,
        scale: 0.775,
        delay: 0,
        duration: logoAnimDuration,
        ease: logoAnimEase,
      },
      {
        item: starBlueRef.current,
        scale: 0.75,
        delay: 0,
        duration: logoAnimDuration,
        ease: logoAnimEase,
      },
      {
        item: heartsRef.current,
        scale: 0.725,
        delay: 0,
        duration: logoAnimDuration,
        ease: logoAnimEase,
      },
      {
        item: twinklesRef.current,
        scale: 0.7,
        delay: 0,
        duration: logoAnimDuration,
        ease: logoAnimEase,
      },

      {
        item: titleRef.current,
        scale: 0.85,
        delay: titleDelay,
        duration: titleDuration,
        ease: 'quint.out',
      },
      {
        item: subtitleRef.current,
        scale: 0.985,
        delay: subTitleDelay,
        duration: titleDuration,
        ease: 'quint.out',
      }
    ]

    for (let i = 0; i < itemSettings.length; i++) {
      const {item, scale, delay, duration, ease} = itemSettings[i]
      gsap.set(item, {scale, opacity:0})
      gsap.to(item, {delay: introDelay + delay, duration, scale:1, opacity:1, ease})
    }

    gsap.set(startEl,{scale: 0.95, y: -5, opacity: 0})
    gsap.to(startEl,{delay: introDelay + startButtonDelay, duration: 1, scale: 1, y: 0, opacity:1, ease:'quad.out', onComplete:()=>{
      setIntroComplete(true)
    }})
  }

  useEffect(()=>{
    componentMounted && animateIn()
  },[componentMounted])


  useEffect(() => {
    // if component mounted is not set
    if (!componentMounted) {
      // set component mounted
      setComponentMounted(true)
    }
  }, [])

  return (
    <div className="intro">
      <div className={`intro-bg-reveal ${animateOut ? 'intro-bg-reveal_hide' : ''}`}></div>
      <div className="intro_logo-container">
        <div className="intro_logo">
          <div className="intro_logo_layers">
            <img ref={heartsRef} src={logo_hearts } />
            <img ref={starBlueRef} src={logo_stars_blue } />
            <img ref={starRedRef} src={logo_stars_red } />
            <img ref={heroRef} src={logo_hero } className={introComplete ? `hue-shift`: ''}/>
            <img ref={twinklesRef} src={logo_twinkles } />
            <img ref={subtitleRef} src={logo_subtitle } />
            <img ref={titleRef} src={logo_title } className={introComplete ? `hue-shift`: ''} />
          </div>
          <button ref={startRef} className={`intro_start-btn active`} onClick={onStart}>{isViewingShareCard ? viewCardButtonLabel : startButtonLabel}</button>
        </div>
      </div>
    </div>
  )
}

export default Intro
