import React, { useState, useEffect } from 'react'
import './style.scss'

import { ReactComponent as Heart } from '../../assets/drop-heart.svg'

const GenericLoader = () => {
  return (
    <div className="generating-indicator">
      <div className="generating-indicator__container">
        <div className="generating-indicator__indicator-icon"><Heart></Heart></div>
      </div>
    </div>
  )
}

export default GenericLoader
