// Prompt: provide casual specific descriptions for the following subject titles in json format with 10 to 15 word descriptions of each subjects that focus on their unique attributes, using simple words, varying the description formats between each, using gender where appropriate, keeping genders balanced, and keeping the subject titles with the original capitalisation as written

export const pregenerated_input =[
  {
    "title": "Cleopatra",
    "description": "Famed Egyptian queen known for her beauty, intelligence, and political acumen."
  },
  {
    "title": "Florence Nightingale",
    "description": "Pioneering nurse who revolutionized healthcare with her emphasis on sanitation."
  },
  {
    "title": "Joan of Arc",
    "description": "Teenage French heroine who led armies to victory with divine guidance."
  },
  {
    "title": "Annie Oakley",
    "description": "Sharpshooter who dazzled with her marksmanship in Buffalo Bill's Wild West show."
  },
  {
    "title": "Anne Bonny",
    "description": "Fearless female pirate known for her daring exploits across the Caribbean."
  },
  {
    "title": "Ada Lovelace",
    "description": "Visionary mathematician considered the first computer programmer for her work on Babbage's Analytical Engine."
  },
  {
    "title": "Mary Shelley",
    "description": "Author of 'Frankenstein,' blending science and fiction in a groundbreaking novel."
  },
  {
    "title": "Edgar Allan Poe",
    "description": "Master of macabre tales and pioneer of the detective fiction genre."
  },
  {
    "title": "Blackbeard",
    "description": "Infamous pirate feared for his fierce appearance and clever tactics at sea."
  },
  {
    "title": "Rapunzel",
    "description": "Girl with incredibly long hair locked in a tower, awaiting rescue."
  },
  {
    "title": "Medusa",
    "description": "Mythical creature with snakes for hair, whose gaze could turn onlookers to stone."
  },
  {
    "title": "Guinevere",
    "description": "Queen of Camelot, whose love triangle led to the kingdom's downfall."
  },
  {
    "title": "Baba Yaga",
    "description": "Slavic witch dwelling in a hut on chicken legs, known for her wisdom and menace."
  },
  {
    "title": "The Witch of the West",
    "description": "Formidable sorceress with a penchant for green, famously melted by water."
  },
  {
    "title": "Gaia",
    "description": "Primordial Earth goddess embodying nature, motherhood, and the origin of life."
  },
  {
    "title": "The Queen of Hearts",
    "description": "Tyrannical monarch in Wonderland, obsessed with beheadings and croquet."
  },
  {
    "title": "Mary Poppins",
    "description": "Magical nanny who transforms lives with a spoonful of sugar and a bit of whimsy."
  },
  {
    "title": "Tinker Bell",
    "description": "Petite fairy known for her jealousy, yet fiercely loyal to Peter Pan."
  },
  {
    "title": "Pandora",
    "description": "Curious woman whose box unleashed woes upon the world, but also hope."
  },
  {
    "title": "Lady Macbeth",
    "description": "Ambitious queen who drove her husband to murder for power, haunted by guilt."
  },

  {
    "title": "A 1950s diner waitress",
    "description": "Cheerful lady in a pink uniform, roller skates, serving milkshakes with a smile."
  },
  {
    "title": "A Kitsune",
    "description": "Mystical fox spirit with multiple tails, known for intelligence, magic, and trickery."
  },
  {
    "title": "A cursed princess",
    "description": "Royal beauty trapped by a spell, waiting for love's true kiss to break it."
  },
  {
    "title": "A mysterious fortune teller",
    "description": "Veiled figure peering into a crystal ball, revealing secrets and futures untold."
  },
  {
    "title": "A mermaid",
    "description": "Oceanic enchantress with a shimmering tail, singing sailors into the depths of love."
  },
  {
    "title": "A Superheroine",
    "description": "Caped crusader battling evil with superpowers, justice, and unbreakable determination."
  },
  {
    "title": "A Valkyrie",
    "description": "Majestic warrior maiden from Norse mythology, guiding fallen heroes to Valhalla."
  },
  {
    "title": "A Devoted Mother",
    "description": "Embodiment of unconditional love, always there with a warm hug and wise words."
  },
  {
    "title": "An Amazonian warrior",
    "description": "Fierce female fighter from a legendary tribe, skilled in combat and strategy."
  },
  {
    "title": "A Femme Fatale Assassin",
    "description": "Deadly beauty using charm and wits as weapons, always one step ahead."
  },
  {
    "title": "Sherlock Holmes",
    "description": "Iconic detective with unmatched intellect, solving London's most baffling mysteries."
  },
  {
    "title": "The Joker",
    "description": "Chaotic clown prince of crime, always plotting against Gotham with a wicked smile."
  },
  {
    "title": "Icarus",
    "description": "Young dreamer who flew too close to the sun with waxen wings."
  },
  {
    "title": "Dorian Gray",
    "description": "Man who remains forever young while his portrait bears the brunt of his sins."
  },
  {
    "title": "Robinson Crusoe",
    "description": "Adventurous castaway surviving alone on an uninhabited island, inventing ways to live."
  },
  {
    "title": "Othello",
    "description": "Tragic hero consumed by jealousy, manipulated into doubting those he loves."
  },
  {
    "title": "A Wizard",
    "description": "Bearded mage wielding ancient spells, protector of mystical secrets and realms."
  },
  {
    "title": "A Cynical Detective",
    "description": "Hard-boiled investigator with a knack for uncovering the dark underbelly of society."
  },
  {
    "title": "A villain",
    "description": "Mastermind of evil, plotting domination or destruction with a chilling laugh."
  },
  {
    "title": "A skilled gladiator",
    "description": "Arena champion, battling for honor and survival in ancient Rome's coliseums."
  },
  
  {
    "title": "A serene monk",
    "description": "Quiet figure in robes, meditating, embodying peace and wisdom."
  },
  {
    "title": "A jellyfish",
    "description": "Graceful sea creature, drifting, with tentacles trailing like delicate lace."
  },
  {
    "title": "An enigmatic time traveler",
    "description": "Mysterious wanderer, crossing eras with a pocket watch and tales of futures and pasts."
  },
  {
    "title": "A giant robot",
    "description": "Massive mechanical marvel, towering over cities, a protector or destroyer."
  },
  {
    "title": "An enchanted forest guardian",
    "description": "Mystical being, cloaked in nature, keeper of ancient woods and their secrets."
  },
  {
    "title": "An emperor penguin",
    "description": "Noble Antarctic bird, braving the cold, regal in its black and white plumage."
  },
  {
    "title": "A mythical kraken",
    "description": "Legendary sea monster, tentacles rising, feared by sailors across the seven seas."
  },
  {
    "title": "A mischievous raccoon",
    "description": "Cunning critter with a masked face, always in search of its next adventure or snack."
  },
  {
    "title": "A unicorn",
    "description": "Magical horse with a spiraling horn, symbol of purity and grace."
  },
  {
    "title": "A talking mirror",
    "description": "Reflective friend offering advice, truths, and occasionally, flattery or criticism."
  },
  {
    "title": "A Snowman",
    "description": "Jolly figure of winter joy, coal eyes bright and carrot nose sharp."
  },
  {
    "title": "A rainbow chicken",
    "description": "Colorful fowl, feathers a spectrum of hues, brightening barnyards."
  },
  {
    "title": "An angel",
    "description": "Celestial messenger with wings, radiating hope and divine guidance."
  },
  {
    "title": "Ebenezer Scrooge",
    "description": "Grumpy old miser who learns the true spirit of Christmas."
  },
  {
    "title": "Elvis Presley",
    "description": "King of Rock 'n' Roll, swiveling hips, legendary voice."
  },
  {
    "title": "The Queen",
    "description": "Regal monarch, embodying tradition, dignity, and the spirit of a nation."
  },
  {
    "title": "The Ghost of Christmas Past",
    "description": "Ethereal spirit showing the shadows of yesteryears, stirring reflection and change."
  },
  {
    "title": "A polar bear",
    "description": "Majestic Arctic giant, fur as white as snow, survivalist of the cold."
  },
  {
    "title": "A penguin",
    "description": "Charming waddler in tuxedo-like feathers, sliding on ice with ease."
  },
  {
    "title": "Leonardo da Vinci",
    "description": "Renaissance genius, artist and inventor, ahead of his time."
  },
  {
    "title": "Harry Potter",
    "description": "Boy wizard with a lightning scar, destined for greatness and battles with dark forces."
  },
  {
    "title": "Albert Einstein",
    "description": "Revolutionary physicist with wild hair, unraveling the mysteries of the universe."
  },
  {
    "title": "Julius Caesar",
    "description": "Roman leader, ambitious and powerful, famously betrayed."
  },
  {
    "title": "A dragon",
    "description": "Fire-breathing mythic beast, hoarding treasure and inspiring awe."
  },
  {
    "title": "Winston Churchill",
    "description": "British Prime Minister, led the UK through WWII with his fierce determination and rousing speeches."
  },
  {
    "title": "Marilyn Monroe",
    "description": "Iconic blonde bombshell, actress and symbol of glamour."
  },
  {
    "title": "Gandhi",
    "description": "Peaceful liberator of India, preaching non-violence and civil disobedience."
  },
  {
    "title": "Mother Teresa",
    "description": "Albanian nun, beacon of compassion, dedicated her life to helping the impoverished and sick."
  },
  {
    "title": "A samurai",
    "description": "Feudal Japanese warrior, living by the code of Bushido, sword ever ready."
  },
  {
    "title": "Galileo Galilei",
    "description": "Father of modern astronomy, daring to tell the truth of the stars."
  },
  {
    "title": "Napoleon Bonaparte",
    "description": "Short in stature, vast in ambition, reshaping Europe through conquest."
  },
  {
    "title": "A talking tree",
    "description": "Ancient arboreal sage, whispering wisdom with the rustle of leaves."
  },
  {
    "title": "Frida Kahlo",
    "description": "Painter of pain and passion, with bold colors and striking self-portraits."
  },
  {
    "title": "A gnome",
    "description": "Garden guardian, small and sprightly, with a penchant for mischief."
  },
  {
    "title": "Thomas Edison",
    "description": "Inventor of the lightbulb, illuminating the world with his genius."
  },
  {
    "title": "Alexander the Great",
    "description": "Conqueror of empires, from Greece to India, undefeated in battle."
  },
  {
    "title": "A fairy",
    "description": "Tiny winged being, sprinkling magic dust, maker of wishes come true."
  },
  {
    "title": "Confucius",
    "description": "Ancient Chinese philosopher, teaching ethics, morality, and proper social relationships."
  },
  {
    "title": "A robot",
    "description": "Metallic marvel of technology, serving humanity with artificial intelligence."
  },
  {
    "title": "King Arthur",
    "description": "Legendary British leader, wielder of Excalibur, founder of the Knights of the Round Table."
  },
  {
    "title": "A yeti",
    "description": "Elusive snowman, roaming the Himalayas, shrouded in mystery and folklore."
  },
  {
    "title": "Pablo Picasso",
    "description": "Revolutionary artist, reshaping art with Cubism, prolific in his creations."
  },
  {
    "title": "Socrates",
    "description": "Founding father of Western philosophy, always questioning to seek the truth."
  },
  {
    "title": "William Shakespeare",
    "description": "Bard of Avon, penning plays of love, tragedy, and human folly."
  },
  {
    "title": "Charles Darwin",
    "description": "Naturalist who proposed evolution by natural selection, changing our understanding of life."
  },
  {
    "title": "Nikola Tesla",
    "description": "Inventor of alternating current, dreaming of wireless energy for all."
  },
  {
    "title": "Abraham Lincoln",
    "description": "16th U.S. President, emancipator, known for his height, honesty, and iconic top hat."
  },
  {
    "title": "Ludwig van Beethoven",
    "description": "Deaf composer, creating symphonies that would become timeless masterpieces."
  },
  {
    "title": "Vincent van Gogh",
    "description": "Tortured artist, painting starry nights and sunflowers, loved posthumously."
  },
  {
    "title": "Sigmund Freud",
    "description": "Father of psychoanalysis, exploring the depths of the human psyche."
  },
  {
    "title": "George Washington",
    "description": "First U.S. president, leading the nation in its earliest, fragile days."
  },
  {
    "title": "Michelangelo",
    "description": "Renaissance sculptor and painter, bringing marble and chapel ceilings to life."
  },
  {
    "title": "Plato",
    "description": "Student of Socrates, exploring ideas of reality and forms through dialogues."
  },
  {
    "title": "Aristotle",
    "description": "Philosopher of ancient Greece, teaching logic, ethics, and the sciences."
  },
  {
    "title": "Archimedes",
    "description": "Mathematician and inventor, famously running naked shouting 'Eureka!' after discovering buoyancy."
  },
  {
    "title": "Helen Keller",
    "description": "Deaf-blind author and activist, overcoming impossible odds to inspire the world."
  },
  {
    "title": "Marco Polo",
    "description": "Venetian explorer, traveling the Silk Road, opening the East to Europe."
  },
  {
    "title": "Louis Pasteur",
    "description": "Scientist who discovered pasteurization, saving countless lives from disease."
  },

  {
    "title": "Tutankhamun",
    "description": "Young Egyptian pharaoh, famed for his intact tomb full of ancient treasures."
  },
  {
    "title": "Rembrandt",
    "description": "Dutch master painter, known for his dramatic use of light and shadow."
  },
  {
    "title": "Edgar Allan Poe",
    "description": "Dark romantic writer, father of the detective story, and master of macabre poetry."
  },
  {
    "title": "Charles Dickens",
    "description": "English novelist who penned classics highlighting the social injustices of his time."
  },
  {
    "title": "Benjamin Franklin",
    "description": "Founding Father, inventor, and polymath, famously experimented with electricity."
  },
  {
    "title": "Alexander Hamilton",
    "description": "First U.S. Secretary of the Treasury, his life now retold in a hit musical."
  },
  {
    "title": "Margaret Thatcher",
    "description": "Britain's first female Prime Minister, known as the Iron Lady for her firm policies."
  },
  {
    "title": "Madonna",
    "description": "Pop icon known for her constant reinvention and groundbreaking music videos."
  },
  {
    "title": "Beyoncé",
    "description": "Queen Bey, a global music superstar and cultural icon, known for her powerful performances."
  },
  {
    "title": "Meryl Streep",
    "description": "Acclaimed actress known for her versatility and numerous Oscar nominations."
  },
  {
    "title": "Julia Roberts",
    "description": "America's sweetheart, her smile lit up the screen in 'Pretty Woman.'"
  },
  {
    "title": "Britney Spears",
    "description": "Pop Princess who defined the late 90s and early 2000s music scene."
  },
  {
    "title": "Rihanna",
    "description": "Music and fashion mogul from Barbados, known for her hit 'Umbrella.'"
  },
  {
    "title": "Celine Dion",
    "description": "Iconic voice from Canada, celebrated for emotional ballads and powerful stage presence."
  },
  {
    "title": "Tina Turner",
    "description": "Rock and roll queen known for her electrifying performances and hit 'Proud Mary.'"
  },
  {
    "title": "A sphinx",
    "description": "Mythical creature with a human head and a lion's body, posing riddles."
  },
  {
    "title": "Robin Hood",
    "description": "Legendary English outlaw, stealing from the rich to give to the poor."
  },
  {
    "title": "A leprechaun",
    "description": "Irish folklore's tiny, bearded figure, hoarding pots of gold at rainbows' ends."
  },
  {
    "title": "Isaac Newton",
    "description": "Scientist who formulated the laws of motion and universal gravitation."
  },
  {
    "title": "A centaur",
    "description": "Half-human, half-horse creature from Greek mythology, symbolizing dual nature."
  },
  {
    "title": "A griffin",
    "description": "Majestic beast with the body of a lion and the head and wings of an eagle."
  },
  {
    "title": "A pirate",
    "description": "Seafaring outlaw searching for treasure, adventure, and freedom on the high seas."
  },
  {
    "title": "Casanova",
    "description": "Famed Venetian lover and adventurer, synonymous with seduction."
  },
  {
    "title": "A Viking",
    "description": "Norse explorer and warrior, raiding and trading across medieval Europe."
  },
  {
    "title": "Mozart",
    "description": "Child prodigy and composer of over 600 works, a genius of classical music."
  },
  {
    "title": "A siren",
    "description": "Enchanting sea creature, luring sailors to their doom with irresistible songs."
  },
  {
    "title": "A sphynx cat",
    "description": "Hairless feline, known for its striking appearance and warm, friendly nature."
  },
  {
    "title": "A pharaoh",
    "description": "Ancient Egyptian ruler, considered a god on earth, buried in massive pyramids."
  },
  {
    "title": "A ninja",
    "description": "Stealthy Japanese warrior, skilled in espionage and guerrilla warfare."
  },
  {
    "title": "A genie",
    "description": "Magical being granting wishes to those who free it from its lamp."
  },
  {
    "title": "A cyclops",
    "description": "One-eyed giant from Greek mythology, known for its strength and smithing."
  },
  {
    "title": "A gargoyle",
    "description": "Stone guardian perched atop buildings, warding off evil spirits."
  },
  {
    "title": "A mummy",
    "description": "Ancient Egyptian dead, embalmed and wrapped in linen, resting in sarcophagi."
  },
  {
    "title": "A werewolf",
    "description": "Human by day, transforming into a fearsome wolf under the full moon."
  },
  {
    "title": "A vampire",
    "description": "Immortal creature of the night, feeding on the blood of the living."
  },
  {
    "title": "A troll",
    "description": "Ugly cave-dwelling creature, known for its mischief and guarding bridges."
  },
  {
    "title": "Dorothy from The Wizard of Oz",
    "description": "Kansas girl transported to a magical land, seeking the Wizard to return home."
  },
  {
    "title": "A mad scientist",
    "description": "Genius inventor with questionable ethics, often creating monsters or dangerous inventions."
  },
  {
    "title": "A gladiator",
    "description": "Ancient Roman fighter, battling for survival and glory in the Colosseum."
  },
  {
    "title": "A knight in shining armor",
    "description": "Valiant warrior of the Middle Ages, upholding honor and protecting the realm."
  },
  {
    "title": "A Greek god",
    "description": "Powerful deity from ancient mythology, ruling over aspects of life and nature."
  },
  {
    "title": "A Roman goddess",
    "description": "Divine figure of ancient Rome, embodying virtues, love, or harvest."
  },
  {
    "title": "A Minotaur",
    "description": "Fierce creature, bull's head on a man's body, trapped in a labyrinth."
  },
  {
    "title": "A magical unicorn",
    "description": "Mythical horse with a spiraling horn, symbolizing purity and grace."
  },
  {
    "title": "A fire-breathing dragon",
    "description": "Terrifying beast guarding treasures, breathing fire, and inspiring fear and awe."
  },
  {
    "title": "A witch on a broomstick",
    "description": "Cackling spell-caster flying through the night, brewing potions and casting hexes."
  },
  {
    "title": "A ghostly apparition",
    "description": "Ethereal spirit haunting the living, a whisper from beyond the grave."
  },
  {
    "title": "An enchanted prince",
    "description": "Royal cursed to live in an altered form, awaiting true love's kiss."
  },
  {
    "title": "A fairy godmother",
    "description": "Benevolent magical being granting wishes with a wave of her wand."
  },
  {
    "title": "A merman",
    "description": "Male counterpart to mermaids, ruling the underwater kingdoms with trident in hand."
  },
  {
    "title": "A leviathan",
    "description": "Massive sea monster from biblical lore, symbolizing chaos and fear in the deep."
  },
  {
    "title": "An orc warrior",
    "description": "Fierce and muscular fantasy creature, known for its brutish strength in battle."
  },
  {
    "title": "A dwarven blacksmith",
    "description": "Stout and sturdy craftsman, forging legendary weapons and armor in mountain forges."
  },
  {
    "title": "An elven archer",
    "description": "Graceful and skilled with the bow, protector of the ancient woods."
  },
  {
    "title": "A hobbit",
    "description": "Small, peaceful being loving comfort, food, and a quiet life in the Shire."
  },
  {
    "title": "A centaur archer",
    "description": "Half-human, half-horse skilled in archery, roaming the forests and plains."
  },
  {
    "title": "A griffin rider",
    "description": "Brave soul bonded with a griffin, soaring the skies, a sight of awe."
  },
  {
    "title": "A pixie",
    "description": "Tiny, mischievous fairy, flitting about with playful curiosity and magic."
  },
  {
    "title": "A chimera",
    "description": "Bizarre creature with parts of a lion, goat, and serpent, breathing fire."
  },
  {
    "title": "A phoenix rising from ashes",
    "description": "Symbol of rebirth and immortality, a fiery bird regenerating anew."
  },
  {
    "title": "A dryad",
    "description": "Tree nymph, spirit of the woods, protector of its arboreal home."
  },
  {
    "title": "A harpy",
    "description": "Winged monster, woman's head and bird's body, snatching up the unwary."
  },
  {
    "title": "A satyr playing a flute",
    "description": "Half-human, half-goat creature of the wild, spreading music and mirth."
  },
  {
    "title": "An alien visitor",
    "description": "Extraterrestrial being from beyond the stars, mysterious and possibly benevolent."
  },
  {
    "title": "A time traveler",
    "description": "Adventurer from another era, exploring the mysteries of past and future."
  },
  {
    "title": "A steampunk inventor",
    "description": "Creator of fantastical machines powered by steam, gears, and imagination."
  },
  {
    "title": "A cyberpunk hacker",
    "description": "Rebel navigating the digital frontier, breaking codes and uncovering secrets."
  },
  {
    "title": "A superhero in a cape",
    "description": "Defender of justice, wielding superpowers to protect the innocent from evil."
  },
  {
    "title": "A mystical shaman",
    "description": "Spiritual guide connecting the physical and spiritual worlds with ancient wisdom."
  },
  {
    "title": "A space explorer",
    "description": "Intrepid voyager of the cosmos, seeking new life and civilizations among the stars."
  },
  {
    "title": "A samurai warrior",
    "description": "Feudal Japanese swordsman living by the Bushido code, honor above all."
  },
  {
    "title": "A ninja in the shadows",
    "description": "Master of stealth and espionage, moving unseen, a whisper in the dark."
  },
  {
    "title": "A Viking berserker",
    "description": "Fierce Norse warrior, entering battle in a frenzied state, unstoppable in rage."
  },
  {
    "title": "A Roman centurion",
    "description": "Commander of a Roman army unit, disciplined, skilled in warfare."
  },
  {
    "title": "A Spartan warrior",
    "description": "Elite fighter of ancient Greece, embodying strength, discipline, and martial prowess."
  },
  {
    "title": "A medieval bard",
    "description": "Traveling poet and musician, telling tales of love, valor, and adventure."
  },
  {
    "title": "A Renaissance painter",
    "description": "Artist of the rebirth, capturing beauty, nature, and human form in vivid detail."
  },
  {
    "title": "An art deco architect",
    "description": "Designer of sleek, geometric structures, blending modernity with elegance."
  },
  {
    "title": "A jazz musician in the Roaring Twenties",
    "description": "Soulful performer of America's music, setting the mood in smoky speakeasies."
  },
  {
    "title": "A 1950s rock-and-roll star",
    "description": "Rebel with a guitar, igniting the youth with catchy tunes and energetic performances."
  },
  {
    "title": "A disco dancer from the 1970s",
    "description": "Groovy mover shaking up the dance floor under glittering disco balls."
  },
  {
    "title": "A punk rocker from the 1980s",
    "description": "Anarchist with a mohawk, challenging norms with loud guitars and DIY ethic."
  },
  {
    "title": "A grunge musician from the 1990s",
    "description": "Flannel-wearing artist of angst and rebellion, defining a generation's sound."
  },
  {
    "title": "A Y2K tech wizard",
    "description": "Digital guru navigating the dawn of the internet, mastering code and connectivity."
  },
  {
    "title": "A millennium futurist",
    "description": "Visionary predicting technological and societal shifts in the new century."
  },
  {
    "title": "A cow",
    "description": "Gentle farm animal, grazing peacefully, provider of milk and beef."
  },
  {
    "title": "An elephant",
    "description": "Majestic creature with tusks and trunk, symbolizing wisdom and memory."
  },
  {
    "title": "A Bengal tiger",
    "description": "Striking wild cat, orange with black stripes, apex predator of the jungle."
  },
  {
    "title": "A blue whale",
    "description": "Colossal marine mammal, largest animal ever, singing deep oceanic ballads."
  },
  {
    "title": "A chimpanzee",
    "description": "Intelligent primate, our close cousin, using tools and showing complex emotions."
  },
  {
    "title": "A red panda",
    "description": "Adorable and elusive, with reddish fur and a bushy tail, climbing trees."
  },
  {
    "title": "A giraffe",
    "description": "Tallest land animal, craning its long neck to munch on treetops."
  },
  {
    "title": "A great white shark",
    "description": "Ocean's fearsome predator, gliding silently, an apex hunter beneath the waves."
  },
  {
    "title": "A grizzly bear",
    "description": "Powerful and solitary, roaming forests and mountains, a symbol of wilderness."
  },
  {
    "title": "A kangaroo",
    "description": "Iconic Australian marsupial, hopping across the Outback, with a pouch for its young."
  },
  {
    "title": "A koala",
    "description": "Cuddly eucalyptus eater, spending its days dozing in trees, an Aussie symbol."
  },
  {
    "title": "A leopard",
    "description": "Graceful big cat, spotted fur, adept at climbing and stealthy hunting."
  },  

  {
    "title": "An orangutan",
    "description": "Intelligent primate with reddish fur, using tools and showing complex social behaviors."
  },
  {
    "title": "A polar bear",
    "description": "Majestic Arctic predator, surviving in extreme cold, a symbol of climate change."
  },
  {
    "title": "A rhinoceros",
    "description": "Powerful herbivore with a distinctive horn, facing threats from poaching and habitat loss."
  },
  {
    "title": "A snow leopard",
    "description": "Elusive mountain cat, beautifully camouflaged for life in the snowy Himalayas."
  },
  {
    "title": "A wolf",
    "description": "Pack hunter known for loyalty and complex social structures, embodying the wild."
  },
  {
    "title": "A zebra",
    "description": "Wild equine with striking black and white stripes, unique to each individual."
  },
  {
    "title": "A hippopotamus",
    "description": "Massive river dweller, surprisingly agile in water, with a dangerous bite."
  },
  {
    "title": "A bald eagle",
    "description": "Iconic bird of prey, symbol of freedom and America's national bird."
  },
  {
    "title": "An octopus",
    "description": "Clever cephalopod with eight arms, capable of astonishing problem-solving and camouflage."
  },
  {
    "title": "A sloth",
    "description": "Tree-dwelling mammal, living life in the slow lane, with a leafy diet."
  },
  {
    "title": "A flamingo",
    "description": "Pink-feathered bird standing on one leg, sifting through water for food."
  },
  {
    "title": "A cheetah",
    "description": "Fastest land animal, reaching speeds in short bursts to catch prey."
  },
  {
    "title": "An anaconda",
    "description": "Massive snake of the Amazon, squeezing life out of its prey with powerful coils."
  },
  {
    "title": "A platypus",
    "description": "Odd mammal with a duck bill, webbed feet, and venomous spurs, laying eggs."
  },
  {
    "title": "A peacock",
    "description": "Bird with spectacular tail feathers, displaying a mesmerizing array of colors."
  },
  {
    "title": "A meerkat",
    "description": "Social desert mammal, standing upright to watch for predators, living in large families."
  },
  {
    "title": "A sea turtle",
    "description": "Ancient mariner of the oceans, navigating vast distances to return to nesting beaches."
  },
  {
    "title": "A hummingbird",
    "description": "Tiny bird capable of hovering mid-air, flapping wings rapidly, attracted to nectar."
  },
  {
    "title": "A falcon",
    "description": "Swift bird of prey, diving at incredible speeds to catch smaller birds mid-flight."
  },
  {
    "title": "A lynx",
    "description": "Elusive forest cat with tufted ears, adapted to cold habitats with thick fur."
  },
  {
    "title": "A porcupine",
    "description": "Spiny rodent, using quills as defense against predators, nocturnal and solitary."
  },
  {
    "title": "A tarantula",
    "description": "Large, hairy spider, less dangerous to humans than its appearance suggests."
  },
  {
    "title": "A Komodo dragon",
    "description": "Largest lizard on Earth, with a venomous bite, dominating Indonesian islands."
  },
  {
    "title": "An armadillo",
    "description": "Armor-plated mammal, rolling into a ball for protection, mainly nocturnal."
  },
  {
    "title": "A manatee",
    "description": "Gentle marine giant, also known as a sea cow, grazing on aquatic plants."
  },
  {
    "title": "A pangolin",
    "description": "Scaled mammal, rolling into a ball when threatened, feeding on ants and termites."
  },
  {
    "title": "An iguana",
    "description": "Colorful lizard, basking in the sun on tropical islands, with a distinctive dewlap."
  },
  {
    "title": "A mystical phoenix",
    "description": "Mythical bird reborn from its ashes, symbolizing renewal and immortality."
  },
  {
    "title": "A time-traveling explorer",
    "description": "Adventurer defying the boundaries of time, witnessing history and future first-hand."
  },
  {
    "title": "A galactic space captain",
    "description": "Commander of a starship, navigating through cosmic dangers and discovering alien worlds."
  },
  {
    "title": "A stealthy ninja",
    "description": "Shadow warrior, mastering the art of invisibility and surprise, from feudal Japan."
  },
  {
    "title": "A majestic sea serpent",
    "description": "Gigantic ocean-dwelling creature, inspiring awe and terror in sailors' tales."
  },
  {
    "title": "An ancient sorcerer",
    "description": "Wielder of arcane magic, summoning powers from beyond to shape reality."
  },
  {
    "title": "A cybernetic super soldier",
    "description": "Futuristic warrior enhanced with technology, battling on the frontlines of tomorrow's wars."
  },
  {
    "title": "A legendary samurai warrior",
    "description": "Feudal Japanese swordsman, living by a strict code of honor, loyalty, and courage."
  },
  {
    "title": "A fierce dragon slayer",
    "description": "Heroic figure, confronting fearsome dragons, armed with sword and shield."
  },
  {
    "title": "A daring pirate captain",
    "description": "Charismatic leader of a rogue crew, seeking treasure and adventure on the high seas."
  },
  {
    "title": "An intrepid arctic explorer",
    "description": "Adventurer braving the frozen extremes, seeking knowledge and the poles' secrets."
  },
  {
    "title": "A mythical unicorn rider",
    "description": "Chosen companion of the elusive unicorn, riding through enchanted forests, a vision of purity."
  },
  {
    "title": "A masterful swordsmith",
    "description": "Artisan forging legendary blades, each a masterpiece of balance and craftsmanship."
  },
  {
    "title": "A dashing highwayman",
    "description": "Romanticized thief of the roads, robbing the rich with charm and a mask."
  },
  {
    "title": "An enchanted mermaid queen",
    "description": "Sovereign of the sea's depths, commanding the ocean's creatures and tides."
  },
  {
    "title": "A renowned grand wizard",
    "description": "Powerful magician, master of spells, guardian of mystical secrets and arcane knowledge."
  },
  {
    "title": "A noble elven archer",
    "description": "Graceful warrior of the forest, unmatched in archery, living in harmony with nature."
  },
  {
    "title": "A courageous lionhearted knight",
    "description": "Valiant defender of the realm, embodying chivalry and bravery, armored for battle."
  },
  {
    "title": "A fiery volcano goddess",
    "description": "Divine entity controlling the fiery wrath of volcanoes, worshipped and feared."
  },
  {
    "title": "A skilled falconer",
    "description": "Master of the art of training falcons for hunting, a bond between human and bird."
  },
  {
    "title": "A charismatic jazz singer",
    "description": "Soulful performer captivating audiences with smooth melodies and emotional depth."
  },
  {
    "title": "An ancient Egyptian pharaoh",
    "description": "God-king of ancient Egypt, ruling over the Nile and its people, builder of pyramids."
  },
  {
    "title": "A Victorian-era detective",
    "description": "Keen investigator solving mysteries in London's foggy streets with logic and intuition."
  },
  {
    "title": "A 1960s hippie",
    "description": "Advocate of peace, love, and freedom, challenging societal norms with vibrant expression."
  },
  {
    "title": "A Greek philosopher",
    "description": "Seeker of wisdom, exploring the mysteries of life, ethics, and the universe."
  },
  {
    "title": "An Elizabethan playwright",
    "description": "Master of the stage, weaving tales of love, tragedy, and comedy in Renaissance England."
  },
  {
    "title": "A cyberpunk hacker",
    "description": "Rebel navigating dystopian futures, breaking into systems and exposing secrets."
  },
  {
    "title": "A samurai warrior",
    "description": "Disciplined and honorable fighter, serving with loyalty and protecting with skill."
  },
  {
    "title": "A 1920s flapper",
    "description": "Symbol of the Roaring Twenties, challenging post-war norms with fashion and dance."
  },
  {
    "title": "A medieval alchemist",
    "description": "Mystic scientist in search of the philosopher's stone, blending magic and early chemistry."
  },
  {
    "title": "A baroque composer",
    "description": "Creator of ornate and grand musical compositions, inspiring emotion and awe."
  },
  {
    "title": "An astronaut from the Apollo missions",
    "description": "Space pioneer, venturing beyond Earth's atmosphere, stepping onto the moon's surface."
  },
  {
    "title": "A 1980s video game designer",
    "description": "Innovator creating digital worlds, shaping the early landscape of video gaming."
  },
  {
    "title": "A Renaissance scientist",
    "description": "Explorer of the natural world, questioning established knowledge, laying the groundwork for modern science."
  },
  {
    "title": "A silent film actor",
    "description": "Star of the silver screen, communicating emotion without words, in the early days of cinema."
  },
  {
    "title": "A beatnik poet",
    "description": "Counter-culture writer, challenging the status quo with rhythmic verse and existential themes."
  },
  {
    "title": "A WWII fighter pilot",
    "description": "Ace of the skies, engaging in dogfights over Europe and the Pacific."
  },
  {
    "title": "A Gothic novelist",
    "description": "Weaver of dark tales, exploring the depths of human psyche and supernatural horrors."
  },
  {
    "title": "A 1970s disco DJ",
    "description": "Master of the turntables, setting the dance floor alight with beats and grooves."
  },
  {
    "title": "An Impressionist painter",
    "description": "Artist capturing fleeting moments with dabs of color, light playing on surfaces."
  },
  {
    "title": "A 1930s jazz trumpeter",
    "description": "Virtuoso of the jazz age, defining the sound of an era with soulful brass."
  },
  {
    "title": "A medieval jester",
    "description": "Court entertainer, clever and quick-witted, using humor to critique and amuse."
  },
  {
    "title": "A Victorian inventor",
    "description": "Engineer of the industrial age, creating innovations that power a new world."
  },
  {
    "title": "A 2000s pop idol",
    "description": "Star of the early digital age, defining trends in music and fashion."
  },
  {
    "title": "A steampunk engineer",
    "description": "Inventor in a world of steam, gears, and goggles, blending Victorian flair with futuristic innovation."
  },
  {
    "title": "A 1940s big band leader",
    "description": "Conductor of swing, leading orchestras in lively rhythms that define a generation."
  },
  {
    "title": "A Roman gladiator",
    "description": "Arena combatant, fighting for survival and glory in ancient Rome's Colosseum."
  },
  {
    "title": "An early 20th-century explorer",
    "description": "Trailblazer charting unknown territories, facing dangers in search of discovery and knowledge."
  },
  {
    "title": "A Mayan astronomer",
    "description": "Ancient scientist, charting the heavens, unlocking the calendar and cosmic mysteries."
  },
  {
    "title": "A Neolithic hunter",
    "description": "Early human, mastering tools and tactics to survive and thrive in a harsh world."
  },
  {
    "title": "A 1950s beatnik",
    "description": "Predecessor to the hippies, embracing jazz, poetry, and a non-conformist lifestyle."
  },
  {
    "title": "A medieval blacksmith",
    "description": "Forging weapons and armor, essential craftsman in the village and castle alike."
  },
  {
    "title": "A 1980s arcade champion",
    "description": "Master of pixels, conquering video games in neon-lit arcades, a high-score hero."
  },
  {
    "title": "A Regency era ballroom dancer",
    "description": "Elegant socialite of Jane Austen's time, mastering the art of dance and courtship."
  },
  {
    "title": "A Byzantine emperor",
    "description": "Ruler of the Eastern Roman Empire, presiding over a realm of art, scholarship, and intrigue."
  },
  {
    "title": "A 1990s dot-com entrepreneur",
    "description": "Pioneer of the internet age, navigating the boom and bust of the early web."
  },
  {
    "title": "An ancient Greek athlete",
    "description": "Competitor in the original Olympic Games, excelling in physical feats for glory and honor."
  },
  {
    "title": "A 1940s film noir detective",
    "description": "Hard-boiled investigator, navigating a world of crime and moral ambiguity in a trench coat."
  },
  {
    "title": "A Ming Dynasty scholar",
    "description": "Learned man of ancient China, contributing to the arts, science, and philosophy."
  },
  {
    "title": "A 1970s punk band member",
    "description": "Rebel with a guitar, challenging the establishment with loud music and anarchic attitude."
  },
  {
    "title": "A Renaissance herbalist",
    "description": "Practitioner of botanical medicine, blending ancient knowledge with the new discoveries of the age."
  },
  {
    "title": "A 1920s aviator",
    "description": "Daring pilot of the early skies, exploring the limits of flight and freedom."
  },
  {
    "title": "A medieval knight",
    "description": "Armored warrior of the Middle Ages, serving lords in battle, bound by chivalry."
  },
  {
    "title": "A 2000s social media influencer",
    "description": "Digital celebrity, shaping trends and opinions through platforms and followers."
  },
  {
    "title": "A 1960s folk singer",
    "description": "Voice of a generation, using music to protest and inspire social change."
  },
  {
    "title": "A Viking explorer",
    "description": "Seafaring adventurer, raiding and discovering new lands across the Atlantic."
  },
  {
    "title": "An Art Nouveau artist",
    "description": "Creator of flowing, naturalistic designs, blending beauty with function in the turn of the century."
  },
  {
    "title": "A 1930s radio broadcaster",
    "description": "Voice of the airwaves, bringing news, entertainment, and comfort during the Great Depression."
  },
  {
    "title": "A medieval monk",
    "description": "Scholar of the scriptorium, preserving knowledge and spirituality in the Middle Ages."
  },
  {
    "title": "A 1980s Wall Street banker",
    "description": "Symbol of excess and ambition, riding the financial booms and busts of the era."
  },
  {
    "title": "A 1990s grunge fan",
    "description": "Admirer of the Seattle sound, wearing flannel and embracing a new era of rock."
  },
  {
    "title": "An ancient Roman senator",
    "description": "Politician of the Roman Republic, wielding power in the forum and the senate."
  },

  {
    "title": "A 1970s environmental activist",
    "description": "Pioneer in the green movement, advocating for Earth's preservation amid industrial growth."
  },
  {
    "title": "A 1950s Hollywood starlet",
    "description": "Glamorous icon of the silver screen, epitomizing the golden age of cinema."
  },
  {
    "title": "A 1960s astronaut",
    "description": "Hero of the space race, venturing into the unknown for humanity's giant leap."
  },
  {
    "title": "A Baroque violinist",
    "description": "Master of strings, bringing the rich, ornate sounds of the Baroque era to life."
  },
  {
    "title": "A 1980s punk vocalist",
    "description": "Rebel voice of a generation, challenging norms with raw lyrics and energetic performances."
  },
  {
    "title": "An ancient Babylonian astronomer",
    "description": "Early stargazer, mapping the heavens, laying foundations for future celestial discovery."
  },
  {
    "title": "A Renaissance cartographer",
    "description": "Explorer of the world on paper, charting unknown lands for a new age of discovery."
  },
  {
    "title": "A 1920s silent movie star",
    "description": "Expressionist of the silent era, captivating audiences without uttering a single word."
  },
  {
    "title": "A medieval monk illuminator",
    "description": "Artistic scribe, bringing manuscripts to life with gold leaf and vibrant pigments."
  },
  {
    "title": "A Victorian botanist",
    "description": "Scholar of flora, cataloging the Empire's exotic plants in lush, detailed illustrations."
  },
  {
    "title": "A 1950s rockabilly musician",
    "description": "Pioneer blending country with rhythm and blues, setting the stage for rock 'n' roll."
  },
  {
    "title": "An 18th-century pirate",
    "description": "Dreaded outlaw of the seas, chasing riches and adventure with a fearsome crew."
  },
  {
    "title": "A 1990s rave DJ",
    "description": "Beatsmith of the underground, spinning anthems for a generation in neon-lit warehouses."
  },
  {
    "title": "An ancient Greek tragedian",
    "description": "Weaver of dramatic tales, exploring the depths of human suffering and fate."
  },
  {
    "title": "A 1930s jazz pianist",
    "description": "Virtuoso of the keys, setting the rhythm of the Jazz Age in smokey clubs."
  },
  {
    "title": "A 2000s indie game developer",
    "description": "Digital artisan, crafting unique, story-driven games from pixels and passion."
  },
  {
    "title": "A Ming Dynasty potter",
    "description": "Creator of exquisite ceramics, from delicate porcelain to robust stoneware, sought after worldwide."
  },
  {
    "title": "A 1970s funk singer",
    "description": "Soulful performer, laying down groovy beats and smooth vocals, igniting the dance floor."
  },
  {
    "title": "A medieval tapestry weaver",
    "description": "Artisan of the loom, narrating history and myth through intricate woven scenes."
  },
  {
    "title": "An Art Nouveau glassmaker",
    "description": "Craftsman of beauty, melting sand into flowing forms and iridescent colors, inspired by nature."
  },
  {
    "title": "A 1980s breakdancer",
    "description": "Athlete of the street, spinning and popping to the beat of hip-hop's rise."
  },
  {
    "title": "A 1990s sitcom actor",
    "description": "Beloved character in living rooms nationwide, bringing laughter and light-hearted escapism."
  },
  {
    "title": "An ancient Persian poet",
    "description": "Master of verse, weaving tales of love, mysticism, and the divine in exquisite poetry."
  },
  {
    "title": "A 1960s peace activist",
    "description": "Champion of non-violence, protesting war with flowers, songs, and unwavering belief in peace."
  },
  {
    "title": "A Regency era novelist",
    "description": "Author of wit and manners, capturing the complexities of love and society in elegant prose."
  },
  {
    "title": "A 1950s beat poet",
    "description": "Literary rebel, breaking conventions with rhythmic, raw poetry of the streets and soul."
  },
  {
    "title": "An Elizabethan actor",
    "description": "Star of the Globe Theatre, bringing Shakespeare's words to life under open skies."
  },
  {
    "title": "A 1940s swing dancer",
    "description": "Energetic performer of the Lindy Hop, the jitterbug, and the ballroom blitz of wartime."
  },
  {
    "title": "A 2000s fashion designer",
    "description": "Trendsetter, blending vintage styles with modern tech, seen on high-fashion runways."
  },
  {
    "title": "A Byzantine icon painter",
    "description": "Devotional artist, imbuing religious figures with sacred beauty in gold and azure."
  },
  {
    "title": "A 1930s film director",
    "description": "Visionary behind the camera, orchestrating noir mysteries and grandiose dramas of the silver screen."
  },
  {
    "title": "A 1970s disco producer",
    "description": "Architect of the disco sound, from the studio to Studio 54's glittering dance floors."
  },
  {
    "title": "A medieval stonemason",
    "description": "Builder of cathedrals, chiseling stone into soaring arches and sacred spaces."
  },
  {
    "title": "A 1980s graffiti artist",
    "description": "Urban muralist, turning city walls into canvases with bold colors and defiant messages."
  },
  {
    "title": "A Renaissance alchemist",
    "description": "Seeker of hidden knowledge, attempting to transform base metals into gold and discover life's elixir."
  },
  {
    "title": "A 1920s Broadway star",
    "description": "Leading light of the stage, captivating audiences with song, dance, and drama."
  },
  {
    "title": "A 1960s mod",
    "description": "Fashion-forward youth, riding scooters and setting trends in London's swinging scene."
  },
  {
    "title": "A Victorian engineer",
    "description": "Innovator of the Industrial Revolution, building bridges, railways, and the framework of the modern world."
  },
  {
    "title": "A 1950s television presenter",
    "description": "Face of the new medium, bringing news and entertainment into the post-war home."
  },
  {
    "title": "An 18th-century landscape painter",
    "description": "Capturing the sublime beauty of nature, from tranquil pastoral scenes to dramatic vistas."
  },
  {
    "title": "A 1990s skateboarder",
    "description": "Icon of youth culture, pioneering tricks and styles in urban skate parks."
  },
  {
    "title": "An ancient Roman architect",
    "description": "Builder of empires, from aqueducts to amphitheaters, mastering concrete and grandeur."
  },
  {
    "title": "A 2000s blogger",
    "description": "Digital diarist, sharing personal insights and observations on the early internet's platforms."
  },
  {
    "title": "A medieval troubadour",
    "description": "Wandering minstrel, serenading with verses of chivalry, love, and adventure."
  },
  {
    "title": "A 1970s soul musician",
    "description": "Singer of depth and feeling, channeling the heartaches and joys of life into soulful rhythms."
  },
  {
    "title": "An Art Deco furniture designer",
    "description": "Craftsman of the future, blending geometric elegance with the luxury of the machine age."
  },
  {
    "title": "A 1980s New Wave singer",
    "description": "Synth-pop icon, with a style as distinctive as the catchy hooks and electronic beats."
  },
  {
    "title": "A 1990s hip-hop artist",
    "description": "Lyricist and beatmaker, narrating the urban experience with rhymes and rhythm."
  },
  {
    "title": "An ancient Chinese calligrapher",
    "description": "Master of the brush, whose strokes are infused with philosophy and poetic beauty."
  },
  {
    "title": "A 1960s surf rock guitarist",
    "description": "Soundtrack of the beach, riding waves of reverb-drenched melodies into the sunset."
  },
  {
    "title": "A Renaissance sculptor",
    "description": "Chiseling marble into lifelike forms, capturing human beauty and divine inspiration."
  },
  {
    "title": "A 1940s film star",
    "description": "Glamorous leading hero of the wartime and post-war screen."
  },
  {
    "title": "A Victorian novelist",
    "description": "Chronicler of the age, weaving tales of love, social critique, and mystery."
  },
  {
    "title": "A 1950s jazz saxophonist",
    "description": "Cool cat of the bebop scene, blowing innovative and soul-stirring solos."
  },
  {
    "title": "An 18th-century explorer",
    "description": "Adventurer charting unknown territories, claiming new lands in the name of empire and science."
  },
  {
    "title": "A 2000s reality TV star",
    "description": "Celebrity of the new millennium, living life in front of the camera for all to see."
  },
  {
    "title": "A medieval armourer",
    "description": "Forging steel into protection and art, outfitting knights for battle and pageantry."
  },
  {
    "title": "A 1980s arcade game developer",
    "description": "Creator of pixelated adventures, fueling quarters into machines and dreams into high scores."
  },
  {
    "title": "A 1990s alternative rock guitarist",
    "description": "Strummer of angst and energy, defining the sound of a disenchanted generation."
  },
  {
    "title": "An ancient Egyptian scribe",
    "description": "Recorder of history and keeper of knowledge, writing on papyrus in hieroglyphs."
  },
  {
    "title": "A 1930s aviator",
    "description": "Pioneering pilot of the skies, pushing the limits of endurance and exploration."
  },
  {
    "title": "A 2000s YouTube influencer",
    "description": "Video creator shaping opinions and trends, from makeup tutorials to viral challenges."
  },
  {
    "title": "A Gothic cathedral builder",
    "description": "Architect of the heavens on earth, crafting spires that reach for the divine."
  },
  {
    "title": "A 1960s beat musician",
    "description": "Sound of the British invasion, with catchy tunes and mop-top hair."
  },
  {
    "title": "A Renaissance painter",
    "description": "Artist of the rebirth, blending realism with spirituality, forever changing the art world."
  },
  {
    "title": "A 1920s blues singer",
    "description": "Voice of soulful depth, singing the trials and tribulations of early African American life."
  },
  {
    "title": "A Victorian poet",
    "description": "Crafting verses of romanticism and melancholy, a mirror to the era's heart and soul."
  },
  {
    "title": "A 1950s sci-fi author",
    "description": "Visionary of the atomic age, exploring the future's possibilities and perils in prose."
  },
  {
    "title": "An 18th-century composer",
    "description": "Maestro of the classical period, whose symphonies and operas fill concert halls to this day."
  },
  {
    "title": "A 1990s rave culture enthusiast",
    "description": "Glowstick warrior, dancing till dawn in a whirl of music, unity, and ecstasy."
  },
  {
    "title": "A medieval herbalist",
    "description": "Healer of the old ways, blending potions and remedies from nature's bounty."
  },
  {
    "title": "A 1970s glam rock star",
    "description": "Androgynous icon, blending rock with theater, glitter, and bold personas."
  },
  {
    "title": "An Art Nouveau architect",
    "description": "Designer of curves and natural forms, harmonizing art and structure in organic beauty."
  },
  {
    "title": "A 1980s synth-pop musician",
    "description": "Architect of catchy synth melodies, defining the sound of an electronic era."
  },
  {
    "title": "A 1990s grunge band drummer",
    "description": "Backbone of the Seattle sound, pounding out gritty rhythms of disaffection."
  },
  {
    "title": "An ancient Viking skald",
    "description": "Bard of the Norse, reciting epic sagas of gods, heroes, and warriors."
  },
  {
    "title": "A Renaissance inventor",
    "description": "Polymath, dreaming up flying machines and inventions far ahead of their time."
  },
  {
    "title": "A 1940s noir detective",
    "description": "Tough and cynical sleuth, navigating a world of crime and moral ambiguity."
  },
  {
    "title": "A 1950s hot rod enthusiast",
    "description": "Rebel with a wrench, customizing cars for speed and style, a precursor to muscle car culture."
  },
  {
    "title": "An 18th-century navigator",
    "description": "Charting seas with sextant and compass, expanding the maps of the known world."
  },
  {
    "title": "A 1990s Internet pioneer",
    "description": "Early adopter and creator on the digital frontier, shaping the web's first wave."
  },
  {
    "title": "A medieval castle builder",
    "description": "Master mason, constructing fortresses of stone to protect and dominate the landscape."
  },
  {
    "title": "A 1970s prog rock keyboardist",
    "description": "Wielding synthesizers and organs to explore the outer limits of rock music's possibilities."
  },
  {
    "title": "An Art Deco graphic designer",
    "description": "Creator of streamlined elegance, capturing the speed and optimism of the modern age."
  },
  {
    "title": "A 1980s hip-hop MC",
    "description": "Poet of the streets, laying down rhymes over beats, a voice for the voiceless."
  },
  {
    "title": "A 1990s tech entrepreneur",
    "description": "Visionary of the dot-com boom, navigating the uncharted waters of the Internet economy."
  },
  {
    "title": "An ancient Greek potter",
    "description": "Craftsman of clay, shaping vessels that bear the stories and daily life of ancient Greece."
  },
  {
    "title": "A 1960s folk protest singer",
    "description": "Minstrel of change, strumming the soundtrack of civil rights marches and anti-war rallies."
  },
  {
    "title": "A Renaissance humanist",
    "description": "Scholar of the classics, promoting a philosophy that celebrates the dignity and worth of all people."
  },
  {
    "title": "A 1920s jazz musician",
    "description": "Innovator of America's music, playing the soundtrack of the Roaring Twenties."
  },
  {
    "title": "A Victorian explorer",
    "description": "Adventurer in pith helmet, charting the unknown corners of the Empire's map."
  },
  {
    "title": "A 1950s atomic age designer",
    "description": "Imagining the future with optimism, in forms and materials inspired by the atomic era."
  },
  {
    "title": "An 18th-century philosopher",
    "description": "Thinker of the Enlightenment, challenging old truths with reason and debate."
  },
  {
    "title": "A 2000s emo band member",
    "description": "Musician of heartfelt angst, expressing the trials of youth with introspective lyrics and dramatic style."
  },
  {
    "title": "A medieval glassblower",
    "description": "Artisan of light, creating stained glass windows that tell biblical tales in vibrant hues."
  },
  {
    "title": "An Art Nouveau jeweler",
    "description": "Creator of adornments, marrying metal and gemstone in flowing, natural designs."
  },
  {
    "title": "A majestic lion",
    "description": "King of the savannah, symbol of strength and courage, ruling with a mighty roar."
  },
  {
    "title": "A playful dolphin",
    "description": "Joyful spirit of the sea, leaping waves and demonstrating remarkable intelligence and sociability."
  },

  {
    "title": "A 1940s film star",
    "description": "Glamorous icon, captivating audiences with elegance and wit in black and white."
  },
  {
    "title": "A mysterious owl",
    "description": "Nocturnal guardian of the forest, wise and silent, with a penetrating gaze."
  },
  {
    "title": "A swift cheetah",
    "description": "The epitome of speed, grace in motion, effortlessly chasing down its prey."
  },
  {
    "title": "A graceful swan",
    "description": "Symbol of beauty and purity, gliding serenely across tranquil waters."
  },
  {
    "title": "A fierce tiger",
    "description": "Majestic predator, stripes blending into the jungle, a solitary hunter."
  },
  {
    "title": "A wise elephant",
    "description": "Gentle giant of the savannah, with deep intelligence and memory."
  },
  {
    "title": "A cunning fox",
    "description": "Sly and adaptable, navigating the woods with cleverness and guile."
  },
  {
    "title": "A loyal dog",
    "description": "Faithful companion, offering unconditional love and protection to its human."
  },
  {
    "title": "A curious cat",
    "description": "Independent explorer, finding wonder in the simplest of spaces and shadows."
  },
  {
    "title": "An elegant giraffe",
    "description": "Tallest of mammals, reaching high into the canopy with a gentle grace."
  },
  {
    "title": "A resilient tortoise",
    "description": "Bearer of a hard shell, moving through life with patience and endurance."
  },
  {
    "title": "A colorful parrot",
    "description": "Vibrant chatterbox of the skies, mimicking sounds with uncanny precision."
  },
  {
    "title": "A noble horse",
    "description": "Steadfast steed, embodying strength and freedom, galloping across open fields."
  },
  {
    "title": "A spirited kangaroo",
    "description": "Dynamic jumper, bounding across the Australian landscape, a pouch of care."
  },
  {
    "title": "A serene koala",
    "description": "Calm and cuddly, dozing among eucalyptus, a symbol of peace."
  },
  {
    "title": "A powerful gorilla",
    "description": "Dominant yet gentle, leading its troop with quiet strength and intelligence."
  },
  {
    "title": "A sleek panther",
    "description": "Shadow of the night, moving with stealth and grace, an elusive beauty."
  },
  {
    "title": "A diligent beaver",
    "description": "Engineer of the animal kingdom, crafting dams with precision and teamwork."
  },
  {
    "title": "A patient penguin",
    "description": "Enduring the Antarctic cold, huddling for warmth, a portrait of resilience."
  },
  {
    "title": "A charming hummingbird",
    "description": "Jewel of the garden, flitting from flower to flower, a blur of color."
  },
  {
    "title": "A robust bison",
    "description": "Mighty wanderer of the plains, symbol of abundance and native spirit."
  },
  {
    "title": "A vibrant peacock",
    "description": "Displaying a kaleidoscope of feathers, a dance of allure and pride."
  },
  {
    "title": "A gentle deer",
    "description": "Eyes full of innocence, treading lightly through the forest's edge."
  },
  {
    "title": "A bold eagle",
    "description": "Sovereign of the skies, emblem of freedom, soaring above the mountains."
  },
  {
    "title": "A whimsical monkey",
    "description": "Playful trickster, swinging through the trees, full of curiosity and mischief."
  },
  {
    "title": "A dainty butterfly",
    "description": "Delicate wings fluttering, a dance of colors across the breeze."
  },
  {
    "title": "A hardworking ant",
    "description": "Embodiment of teamwork, building a kingdom beneath our feet, strength in unity."
  },
  {
    "title": "A sturdy rhinoceros",
    "description": "Armor-plated herbivore, charging forward with determination, a symbol of resilience."
  },
  {
    "title": "A playful otter",
    "description": "Aquatic jester, sliding and diving, finding joy in life's simple pleasures."
  },
  {
    "title": "A regal falcon",
    "description": "Hunter of the skies, precision in flight, a master of speed."
  },
  {
    "title": "A stealthy leopard",
    "description": "Solitary shadow, blending into the foliage, an ambush predator of elegance."
  },
  {
    "title": "A vibrant flamingo",
    "description": "Standing on one leg, a splash of pink against the water's blue."
  },
  {
    "title": "A sociable meerkat",
    "description": "Vigilant and communal, standing guard, a network of tunnels and trust."
  },
  {
    "title": "A formidable crocodile",
    "description": "Ancient hunter, armored and patient, ruler of riverine realms."
  },
  {
    "title": "A tranquil dove",
    "description": "Symbol of peace and hope, cooing softly, spreading calm."
  },
  {
    "title": "A resourceful octopus",
    "description": "Master of escape, changing colors and shapes, intelligence deep in the sea."
  },
  {
    "title": "A radiant starfish",
    "description": "Star of the ocean floor, regenerating and resilient, a marvel of nature."
  },
  {
    "title": "A vigilant hawk",
    "description": "Keen-eyed sentinel, soaring high, a sharp presence over the landscape."
  },
  {
    "title": "A whimsical seahorse",
    "description": "Drifting dancer of the sea, a unique courtship, fatherhood in the deep."
  },
  {
    "title": "A robust walrus",
    "description": "Giant of the Arctic, tusks of ivory, a community on the ice."
  },
  {
    "title": "A harmonious dolphin",
    "description": "Intelligent and social, communicating in clicks, an acrobat of the waves."
  },
  {
    "title": "A steadfast camel",
    "description": "Desert voyager, enduring the harshest conditions, a reservoir of patience."
  },
  {
    "title": "A perceptive chameleon",
    "description": "Ever-changing, blending seamlessly, an artist of adaptation and survival."
  },
  {
    "title": "A commanding orca",
    "description": "Apex predator of the sea, family-oriented, navigating the deep with power and grace."
  },
  {
    "title": "A nimble squirrel",
    "description": "Energetic forager, acrobat of the trees, preparing diligently for winter's chill."
  },
  {
    "title": "A tranquil turtle",
    "description": "Bearer of a shell, journeying slowly, wisdom in each deliberate step."
  },
  {
    "title": "A radiant goldfish",
    "description": "Glimmering in the water, a simple beauty, tranquility in motion."
  },
  {
    "title": "A whimsical fairy",
    "description": "Enchanting creature of folklore, fluttering on gossamer wings, a whisper of magic."
  },
  {
    "title": "A fearless astronaut",
    "description": "Explorer of the cosmos, braving the unknown, humanity's envoy among the stars."
  },
  {
    "title": "A wise elder",
    "description": "Bearer of stories and wisdom, guiding the next generation with experience and insight."
  },
  {
    "title": "An enigmatic spy",
    "description": "Master of disguise and secrets, moving unseen in a world of intrigue."
  },
  {
    "title": "A charming bard",
    "description": "Weaver of words and music, captivating hearts with tales of heroism and heartache."
  },
  {
    "title": "A rogue pirate",
    "description": "Adventurer of the high seas, seeking treasure and freedom beyond the horizon."
  },
  {
    "title": "A noble knight",
    "description": "Valor and virtue clad in armor, wielding sword for honor and protection."
  },
  {
    "title": "An ancient mummy",
    "description": "Preserved through time, a silent witness to millennia, wrapped in mystery."
  },
  {
    "title": "A cunning detective",
    "description": "Solver of puzzles, piecing together clues from shadows, a mind sharp as a tack."
  },
  {
    "title": "A powerful sorcerer",
    "description": "Wielder of arcane energies, crafting spells of awe and protection, a scholar of the mystical."
  },
  {
    "title": "A fierce gladiator",
    "description": "Warrior of the arena, fighting for survival and glory under the watchful eyes of Rome."
  },
  {
    "title": "A brave firefighter",
    "description": "Hero in the face of danger, battling flames to save lives and homes."
  },
  {
    "title": "An adventurous archaeologist",
    "description": "Seeker of ancient relics, uncovering the secrets of civilizations long past."
  },
  {
    "title": "A genius scientist",
    "description": "Innovator and thinker, pushing the boundaries of knowledge and discovery."
  },
  {
    "title": "A relentless bounty hunter",
    "description": "Tracker of fugitives, navigating the edge of law and morality for the prize."
  },
  {
    "title": "A charismatic leader",
    "description": "Visionary, inspiring others to follow a path towards change and success."
  },
  {
    "title": "A savage barbarian",
    "description": "Warrior of the wild, unbridled and fierce, a force of nature unleashed."
  },
  {
    "title": "A skilled archer",
    "description": "Master of the bow, precision in every shot, a silent guardian."
  },
  {
    "title": "A ruthless assassin",
    "description": "Shadow in the night, executing missions with lethal precision, allegiance to the highest bidder."
  },
  {
    "title": "A daring stunt performer",
    "description": "Thrill-seeker, defying death with each leap, roll, and explosion, cinema's unsung hero."
  },
  {
    "title": "A talented musician",
    "description": "Artist of melody, expressing the inexpressible through rhythms and harmonies."
  },
  {
    "title": "A graceful dancer",
    "description": "Poetry in motion, telling stories with the body, captivating audiences with movement."
  },
  {
    "title": "A visionary artist",
    "description": "Creator of new worlds, challenging perceptions with brush, pen, and imagination."
  },
  {
    "title": "A skilled blacksmith",
    "description": "Forger of metal, from the practical to the fantastical, strength and skill combined."
  },
  {
    "title": "A stoic soldier",
    "description": "Defender of ideals, enduring hardship with resilience, a testament to duty and courage."
  },
  {
    "title": "A cunning thief",
    "description": "Master of stealth and acquisition, living by wits and agility, shadow among shadows."
  },
  {
    "title": "A compassionate healer",
    "description": "Guardian of health, mending wounds and curing ailments with knowledge and care."
  },
  {
    "title": "A relentless journalist",
    "description": "Seeker of truth, unearthing stories buried deep, a voice for the voiceless."
  },
  {
    "title": "A dashing pilot",
    "description": "Aviator of the skies, navigating through clouds and storms with unrivaled skill."
  },
  {
    "title": "A noble diplomat",
    "description": "Broker of peace, navigating the complex dance of politics with grace and wisdom."
  },
  {
    "title": "A creative inventor",
    "description": "Dreamer of devices, turning imagination into reality, shaping the future with innovation."
  },
  {
    "title": "A daring explorer",
    "description": "Trailblazer of the unknown, charting new territories with courage and curiosity."
  },
  {
    "title": "A cunning lawyer",
    "description": "Strategist of the courtroom, wielding law and logic to advocate and persuade."
  },
  {
    "title": "A dedicated teacher",
    "description": "Moulder of minds, imparting knowledge and inspiration, shaping futures with dedication."
  },
  {
    "title": "A resourceful survivalist",
    "description": "Adaptable and resilient, thriving in the harshest conditions, a testament to human will."
  },
  {
    "title": "A powerful wizard",
    "description": "Conjurer of wonders, master of elements, guardian of ancient secrets."
  },
  {
    "title": "A skilled chef",
    "description": "Culinary artist, transforming ingredients into gastronomic experiences, a dance of flavors."
  },
  {
    "title": "A fearless ranger",
    "description": "Protector of the wilds, moving silently through forests, a harmony with nature."
  },
  {
    "title": "A charming con artist",
    "description": "Weaver of illusions, playing roles with ease, always one step ahead."
  },
  {
    "title": "A ruthless gangster",
    "description": "Lord of the underworld, ruling with cunning and brutality, a shadow over the city."
  },
  {
    "title": "A legendary athlete",
    "description": "Champion of sport, pushing the limits of physical excellence, inspiring with every victory."
  },
  {
    "title": "A hardened mercenary",
    "description": "Soldier for hire, loyalty to the highest bidder, battle-scarred and unyielding."
  },
  {
    "title": "A sophisticated spy",
    "description": "Agent of intrigue, blending into high society and espionage with equal finesse."
  },
  {
    "title": "A mystical druid",
    "description": "Keeper of natural balance, wielding magic rooted in earth and tradition."
  },
  {
    "title": "A daring acrobat",
    "description": "Defier of gravity, captivating audiences with leaps and bounds, a dance of risk."
  },
  {
    "title": "A vigilant guard",
    "description": "Watcher in the shadows, safeguarding peace with vigilance and strength."
  },
  {
    "title": "A whimsical jester",
    "description": "Court entertainer, cloaked in folly, speaking truths through laughter and satire."
  }
]
