// src/MessageInput.js
import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import './style.scss'

import TextCarousel from '../TextCarousel'

import {isMobile, isTablet} from '../../config/config'

const MessageInput = forwardRef(({ className, placeholderText, input, hasSubmit, hasInput, allowAutoHeight, onInputChange, onKeyDown, onEnterKeyDown, onSend, placeholderItems, placeholderIndex, placeholderKey  }, ref) => {
  const textareaRef = useRef()
  const autoHeightMinMobile = 520
  const autoHeightMinDesktop = 750
  const autoHeightMin = isMobile ? autoHeightMinMobile : autoHeightMinDesktop
  const canExpand = allowAutoHeight && window.innerHeight > autoHeightMin
  const maxScrollHeight = (isMobile && !isTablet) ? 70 : 130

  // expose the focus method to parent components
  useImperativeHandle(ref, () => ({
    focus: () => {
      textareaRef.current.focus();
    },
    focusAndSelect: () => {
      textareaRef.current.focus()
      textareaRef.current.setSelectionRange(0, textareaRef.current.value.length)
    }
  }));

  const [allowSelection, setAllowSelection] = useState(true) // allow selection of text in input field (toggled off when not focused to allow select all to avoid selecting input placeholder text)
  const [inputFocused, setInputFocused] = useState(false) // allow selection of text in input field (toggled off when not focused to allow select all to avoid selecting input placeholder text)

  // auto grow textarea height
  useEffect(() => {
    
    if (canExpand) {
      textareaRef.current.style.height = 'auto';
      let scrollHeight = textareaRef.current.scrollHeight;
      if (scrollHeight > maxScrollHeight) { // adjust the maximum height based on device type
        scrollHeight = maxScrollHeight;
      }
      textareaRef.current.style.height = scrollHeight + 'px';
    }
  }, [input, allowAutoHeight])

  // prevent input placeholder text being selectable when text input is not focused
  const handleFocus = () => {
    setAllowSelection(true)
    setInputFocused(true)
  }
  const handleBlur = () => {
    setAllowSelection(false)
    setInputFocused(false)
  }

  // handle message input key down event
  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      if (isMobile) {
        // if mobile
        event.preventDefault()
        if (onEnterKeyDown) {
          onEnterKeyDown();
        }
        if (hasSubmit) {
          onSend() // send message
        }
      } else {
        // is desktop
        if (!event.shiftKey) {
          // if shift key is not pressed
          event.preventDefault()
          if (onEnterKeyDown) {
            onEnterKeyDown();
          }
          if (hasSubmit) {
            onSend() // send message
          }
        }
      }
    }
  }

  const containerClassName = `container ${className} ${canExpand ? 'message-input__container--autoheight' : 'message-input__container--overflows'}`

  return (
    <div className={containerClassName}>
      {/* <div className="inputLabel">Your deepest fear:</div> */}
      <div className={`message-input ${hasSubmit ? 'has-submit-button' : ''}`} data-disable-selection={!allowSelection ? true : null}>
        {/* { placeholderItems && placeholderItems.length ? <div className={`message-input_placeholder-container ${!input && !inputFocused ? 'message-input_placeholder-active' : ''}`}>
          <TextCarousel textTimes={placeholderItems} placeholderIndex={placeholderIndex} placeholderKey={placeholderKey} />
        </div> : null } */}
        <textarea
          className='message-input__textarea'
          ref={textareaRef}
          value={input}
          rows={1}
          onChange={e => onInputChange(e.target.value)}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={placeholderText}
          // autoFocus={true}
        />
        {/* { hasSubmit ? <button
          className={`message-input__button`}
          onClick={onSend}
          data-has-input={hasInput ? true : null}
        >
          { enterButtonLabel }
        </button> : null } */}
      </div>
    </div>
  )
})

export default MessageInput
