import React, {useEffect} from 'react'
import './style.scss'
import { serverTooBusyErrorMessage, serverForbiddenMessage, serverErrorMessage, serverRejectionErrorMessage, serverGeneralErrorTitle, serverTooBusyErrorTitle } from "../../config/copy";

const ErrorPage = ({ errorCode, setIsErrorVisible }) => {

  useEffect(() => {
    setIsErrorVisible(true);

    return () => setIsErrorVisible(false);
  }, []);

  /**
   * Retrieves the title for the error page based on the error code.
   *
   * @returns {string} The error message corresponding to the error code.
   */
  const getErrorTitle = () => {
    switch (errorCode) {
      case 429: // 429 Too Many Requests
        return serverTooBusyErrorTitle;
      default:
        return serverGeneralErrorTitle;
    }
  }

  /**
   * Retrieves the error message based on the error code.
   *
   * @returns {string} The error message corresponding to the error code.
   */
  const getErrorMessage = () => {
    switch (errorCode) {
      case 500: // 500 Internal Server Error
        return serverRejectionErrorMessage;
      case 429: // 429 Too Many Requests
        return serverTooBusyErrorMessage;
      case 404: // 404 Not Found
        return serverErrorMessage;
      case 403: // 403 Forbidden
        return serverForbiddenMessage;
      case 400: // 400 Bad Request
        return serverRejectionErrorMessage;
      default:
        return serverRejectionErrorMessage
    }
  }

  // export const serverErrorMessage = `Oops! Cupid got lost in the cloud. Please try again.`;
  // export const serverTooBusyErrorMessage = `Sorry, Cupid is too busy writing other poems. Please try again later.`;
  // export const serverRejectionErrorMessage = `Sorry, Cupid has gone on holiday, hopefully he will be back soon.`;
  // export const serverForbiddenMessage = `Sorry, Cupid’s lawyer says no.`;

  return <div className="errorpage">
    <div className={`errorpage-bg-reveal`}></div>
    <div className="errorpage_container">
      <div className="errorpage_inner">
        <h1>{getErrorTitle()}</h1>
        <p>{getErrorMessage()}</p>
        <p>In the meantime, check out some of our other projects.</p>
        <a href="https://resn.co.nz" target="_blank" className={"errorpage_featuredlink"}>resn.co.nz</a>
      </div>
    </div>
  </div>

}

export default ErrorPage
