import React, { useRef } from 'react'
import './style.scss'

const MessageOutput = ({ titleText, outputText }) => {

  return (
    <div className="message-output__scroll-container">
      <div className="message-output__title">{titleText}</div>
      <div className="message-output__text">{outputText}</div>
    </div>
  )
}

export default MessageOutput
