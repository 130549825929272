// src/useChat.js
import {useRef, useEffect, useCallback} from 'react'
import { apiEndpoint } from '../config/config'
import { testResponse } from '../config/testResponseData'
import { serverErrorMessage, contentPolicyErrorMessage, serverRejectionErrorMessage, serverResponseErrorMessage, serverTooBusyErrorMessage } from '../config/copy'
import { incrementTries } from '../utils/attempts.js'

const useGenerationRequest = (subjectAInput, subjectBInput, subjectADescInput, subjectBDescInput, setResponseData, setIsLoading, setError, debugMode, allowExtra) => {

  const skipGeneration = window.location.search.includes('skip')
  const lowQuality = window.location.search.includes('low')

  const setErrorResponse = (data, sendError, sendErrorUserMsg, sendErrorMsg) => {
    sendError = true
    switch (data.errorName) {
      case 'content_policy_violation':
        sendErrorUserMsg = contentPolicyErrorMessage
        break
      case 'openai_rejection':
        sendErrorUserMsg = serverRejectionErrorMessage
        break
      case 'openai_request_error':
        sendErrorUserMsg = serverResponseErrorMessage
        break
      case 'too_many_requests':
        sendErrorUserMsg = serverTooBusyErrorMessage
        break
      case 'too_many_user_requests':
        sendErrorUserMsg = serverTooBusyErrorMessage
        break
      case 'too_many_user_requests':
        sendErrorUserMsg = serverTooBusyErrorMessage
        break
      case 'too_many_user_api_requests':
        sendErrorUserMsg = serverTooBusyErrorMessage
        break
      case 'too_many_server_requests':
        sendErrorUserMsg = serverTooBusyErrorMessage
        break
      default:
        sendErrorUserMsg = serverErrorMessage
        break
    }
    sendErrorMsg = debugMode && data.debugError ? ' ' + data.debugError : null
    return {sendError, sendErrorUserMsg, sendErrorMsg}
  }

  const sendMessage = useCallback(async () => {
    if (skipGeneration) {
      testResponse.isUserResult = true
      setResponseData(testResponse)
      return
    }
    setError(null)
    setIsLoading(true)

    if (subjectAInput.trim() === '' || subjectBInput.trim() === '') {
      return false
    }

    let sendError = false
    let sendErrorUserMsg = null
    let sendErrorMsg = null

    const body = {
      action: 'generateResult',
      subjectA: subjectAInput.trim(),
      subjectB: subjectBInput.trim(),
      subjectADesc: subjectADescInput.trim(),
      subjectBDesc: subjectBDescInput.trim(),
    }

    if (lowQuality) body.lowQuality = lowQuality
    if (allowExtra) body.extra = allowExtra

    if (debugMode) body.debug = debugMode

    fetch(apiEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
    .then(response => response.text())
    .then(responseText => {
      const data = JSON.parse(responseText)
      const message_output = data.error ? false : data.message
      const hasError = data.error || typeof message_output === 'string' || !message_output

      if (hasError) {
        const errorResponse = setErrorResponse(data, sendError, sendErrorUserMsg, sendErrorMsg)
        sendError = errorResponse.sendError
        sendErrorUserMsg = errorResponse.sendErrorUserMsg
        sendErrorMsg = errorResponse.sendErrorMsg

        let errorMessage = serverErrorMessage
        if (sendErrorUserMsg) errorMessage = sendErrorUserMsg
        setError(errorMessage)
        setIsLoading(false)
        return
      }

      incrementTries()

      if (message_output) {
        message_output.isUserResult = true
      }

      setResponseData(message_output, true)
      setIsLoading(false)
    })
    .catch(error => {
      console.error(error)
      sendError = true
      sendErrorMsg = debugMode ? ' ' + error : null
      setIsLoading(false)
      if (sendError) {
        let errorMessage = serverErrorMessage
        setError(errorMessage)
      }
    })

  }, [subjectAInput, subjectBInput, subjectADescInput, subjectBDescInput, setIsLoading, setError, skipGeneration, lowQuality, debugMode, setResponseData])

  return {
    sendMessage
  }
}

export default useGenerationRequest
