import { Howl, Howler } from 'howler'
import Track from './Track'

window.Howler = Howler

// Matches HTML5 MediaElement API
export default class Sound extends Track {
  constructor(url, format, concurrent = 1, sprite) {
    super()

    this.obj = new Howl({
      src: [url],
      preload: false,
      sprite,
      format,
    })

    this.concurrent = concurrent
    this.soundIDs = []
    this._idIndex = -1

    this.bindEvents()
  }

  load() {
    return new Promise(resolve => {
      this.obj.once('load', resolve)
      this.obj.load()
    })
  }

  destroy() {
    this.obj.unload()
  }

  addId(id) {
    if (this.soundIDs.length < this.concurrent) {
      this.soundIDs.push(id)
    }
  }

  get singleId() {
    return this.concurrent === 1
  }

  get currentId() {
    return this.soundIDs[this._idIndex] || undefined
  }

  get nextId() {
    this._idIndex = this._idIndex++ % this.concurrent
    return this.soundIDs[this._idIndex] || undefined
  }

  playing(id) {
    return this.obj.playing(id)
  }

  play = spriteLabel => {
    let id
    // This solve only works with the assumption where we know any audio that has a sprite will have concurrent=1
    if (this.singleId) {
      id = spriteLabel
      this.obj.play(spriteLabel)
    } else {
      id = this.nextId
      if (id) {
        this.obj.play(id)
      } else {
        id = this.obj.play()
        this.addId(id)
      }
    }

    return id
  }

  pause = () => {
    this.obj.pause(this.currentId)
  }

  stop = () => {
    this.obj.stop(this.currentId)
  }

  fade = (to, duration) => {
    this.obj.off('fade')
    this.obj.fade(this.volume, to, duration)
  }

  fadeOutToStop = duration => {
    this.obj.off('fade')
    this.obj.fade(this.volume, 0, duration)
    this.obj.once('fade', () => {
      this.obj.stop()
    })
  }

  fadeOutToPause = duration => {
    this.obj.off('fade')
    this.obj.fade(this.volume, 0, duration)
    this.obj.once('fade', () => {
      this.obj.pause()
    })
  }

  get volume() {
    return this.obj.volume(this.currentId)
  }

  set volume(val) {
    this.obj.volume(val)
  }

  get muted() {
    return this.obj.muted
  }

  set muted(val) {
    this.obj.mute(val, this.currentId)
  }

  get currentTime() {
    return this.obj.seek()
  }

  set currentTime(val) {
    this.obj.seek(val, this.currentId)
  }

  get duration() {
    return this.obj.duration()
  }

  get paused() {
    return !this.obj.playing()
  }

  get rate() {
    return this.obj.rate()
  }

  set rate(val) {
    this.obj.rate(val)
  }

  get loop() {
    return this.obj.loop()
  }

  set loop(val) {
    this.obj.loop(val)
  }
}
