import * as Tone from 'tone'

class TTSSoundPlayer {
  constructor(feedbackDelay, feedbackAmount, pitchShift, playbackRate) {
    this.feedbackDelay = feedbackDelay;
    this.feedbackAmount = feedbackAmount;
    this.pitchShift = pitchShift;
    this.playbackRate = playbackRate;

    this.isMuted = false;
  }

  async initPlayer(soundUrl) {
    this.isLoaded = false;

    if (this.player) {
      this.player.stop();
      this.player.dispose();
    }

    this.player = new Tone.Player({
      url: soundUrl,
      autostart: false,
    });
    this._updateMuteState();

    this.player.playbackRate = this.playbackRate;

    this.isLoaded = false;

    // this.player.toDestination(); // Connect the player to the destination (speakers)

    const FeedbackDelay = new Tone.FeedbackDelay(
      Number(this.feedbackDelay),
      Number(this.feedbackAmount)
    ).toDestination();
  
    const PitchShift = new Tone.PitchShift(
      Number(this.pitchShift)
    ).connect(FeedbackDelay);

    this.player.connect(PitchShift);

    this.curSoundUrl = soundUrl;

    // Load the audio file; Tone.loaded() returns a promise that resolves when all players are loaded
    Tone.loaded().then(() => {
      // console.log('Audio loaded successfully');
      this.isLoaded = true;
    }).catch(e => {
      console.error('Error loading audio:', e);
    });
  }

  async load(soundUrl) {
    // if (this.isMuted) {
    //   return;
    // }
    if (this.curSoundUrl != soundUrl) {
      this.stop();

      this.initPlayer(soundUrl)
    }
  }

  // Method to play the audio
  async play() {
    // if (this.isMuted) {
    //   return;
    // }
    if (!this.curSoundUrl) {
      return;
    }
    
    // If the audio is not yet loaded, wait for it to load
    await Tone.loaded();
    this.isLoaded = true;
      
    // Start or restart the player
    if (!this.player.state === "started") {
      this.player.restart();
    } else {
      this.player.start();
    }
  }

  // Method to stop the audio
  stop() {
    if (!this.curSoundUrl) {
      return;
    }

    if (this.player.state === "started") {
      this.player.stop(); // Stop the player if it is playing
    }
    // this.curSoundUrl = null;
  }

  mute(muted) {
    this.isMuted = muted;
    this._updateMuteState();
  }
  
  _updateMuteState() {
    if (this.player) {
      if (this.isMuted) {
        this.player.volume.value = -Infinity; // Effectively mute this player
      } else {
        this.player.volume.value = 0; // Restore volume
      }
    }
  }
}

export default TTSSoundPlayer;